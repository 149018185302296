import React from "react";
import { Row, Col, Form, Image } from "react-bootstrap";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import axios from 'axios';
import { useGlobal, useDispatch, Fragment, setGlobal } from "reactn";

import { Field, Button, Select } from "Components";
import { Close } from "./Components";
import { namedRoutes } from "Routes";
import { BASE_URL } from "Config";

export const Schema = Yup.object({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  regionid: Yup.number().required("Select a region"),
  yearofservice: Yup.string().required("Select service year"),
});

export default class Search extends React.Component {

  state = {
    regions: [{value:"1",label:"hrr"}],
    loading: true,
    searching: false
  }

  componentDidMount(){
    this.getRegions();
  }

  getRegions = () => {
    axios.get(`https://2x190vwi57.execute-api.eu-west-1.amazonaws.com/v1/regions`)
    .then( res => {
      console.log(res);
      this.setState({regions: res.data, loading: false})
    })
    .catch( e => {
      console.error(e)
    })
  } 

  findNssNumber(params){
    this.setState({searching:true})
    return axios.post(`${BASE_URL}/api/find-nss-number`, params)
    .then( res => {
      console.log(res.data);
      setGlobal({results: res.data.certificates})
      this.props.history.push({
        pathname: namedRoutes.home.results, 
        state: { data: res.data }
      });
      return 
    })
    .catch( e => {
      console.error(e);
      alert(e.response.data.message)
      this.setState({searching:false})
    })
  }

  render(){
  const { history } = this.props;
  return (
    <div className="search-page py-32">
      <div className="search-page__content container">
        <div className="search-page__content__close d-flex mb-3 mb-md-5">
          <Close />
        </div>
        <div className="search-page__content__body py-3 py-md-4">
          <Row className="justify-content-center">
            <Col md="10" lg="8">
              <h1 className="header font-size-18 font-size-md-24 mb-32 font-weight-700 font-weight-md-400">
                Find your NSS Number
              </h1>

               <Formik
                validationSchema={Schema}
                initialValues={{
                  firstname: "",
                  lastname: "",
                  regionid: "",
                  regions: this.state.regions,
                  yearofservice: "",
                }}
                enableReinitialize={true}
                children={(props) => <SearcNssForm searching={this.state.searching} regions={this.state.regions} {...props} />}
                onSubmit={ async (params, actions) => {
                  /**
                   * Personally I'd push the result of this query to the global state
                   * const [, setResults] = useGlobal("results");
                   *
                   * where the useGlobal hook is from the reactn package
                   */
                  console.log(params)
                  actions.setSubmitting(true);
                  await this.findNssNumber(params); 
                  actions.setSubmitting(false); 
                  
                }}
              />

              <Row>
                <Col sm="12" md="10" lg="7">
                  <div className="d-flex justify-content-between align-items-center footer">
                    <Link
                      className="blue-text"
                      to={namedRoutes.home.requirements}
                    >
                      See a list of full Requirements
                    </Link>
                    <Link className="blue-text" to={namedRoutes.home.support}>
                      Get Help
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
              }
}

export function SearcNssForm({
  handleSubmit,
  isSubmitting,
  values,
  setFieldValue,
}) {
  const { firstname, lastname, regionid, yearofservice, regions=[], searching=false } = values;
  /**
   * I'm assuming the regions and the year of service
   * are all coming from the database
   *
   * You can use the react-axios <Get /> component in the form directly
   * to get the data
   * or
   * You can do that in the effect hooks and assign it to a local
   * state
   *
   * Personally I'm go in for the latter just to keep the code clean
   */
  //alert('hi')
  const getYears = ( ) => {
    const currentYear = 2020;
    let years =[]; 
    var times = 40;
    for(var i=0; i < times; i++){
      years.push(
        {
          value: `${(currentYear-i-1)}/${currentYear-i}`,
          label: `${(currentYear-i-1)}/${currentYear-i}`,
        })
    }
    console.log({years})
    return years; 
  }
console.log(regions)
  return (
    <Form className="py-md-4">
      <Row>
        <Col md="6">
          <Field
            name="firstname"
            value={firstname || ""}
            label={{ value: "Enter your first name" }}
          />
        </Col>
        <Col md="6">
          <Field
            name="lastname"
            value={lastname || ""}
            label={{ value: "Enter your last name" }}
          />
        </Col>

        <Col md="6">
          <Field
            name="regionid"
            value={`${regionid || ""}`}
            label={{ value: "Select Region" }}
            component={Select}
            options={regions.map(region => { 
              return {
                value: region.region_id, label: region.region 
              }
            })}
            placeholder=""
            className="px-0 --border-none"
            onChange={(params) => setFieldValue("regionid", params.value)}
          />
        </Col>

        <Col md="6" className="pb-3">
          <Field
          style={{overflow: 'scroll'}}
            name="yearofservice"
            value={`${yearofservice || ""}`}
            label={{ value: "Select year of service" }}
            component={Select}
            options={getYears()}
            placeholder=""
            className="px-0 --border-none"
            onChange={(params) =>
              setFieldValue("yearofservice", params.value)
            }
          />
        </Col>

        <Col sm="12">
          <Button
            value="Search"
            isSubmitting={isSubmitting}
            type="submit"
            className="btn--success --h-56"
            onClick={handleSubmit}
          />
        </Col>
      </Row>
    </Form>
  );
}
