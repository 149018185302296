import React, { useGlobal } from "reactn";
/**
 * packages
 */
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/**
 * Route to base components
 */
import { Home, Dashboard } from "Views";
import ScrollToTop from "Components/ScrollToTop";

export default function Routes() {
  const [isAuthenticated] = useGlobal("isAuthenticated");

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {isAuthenticated && <Route path="/dashboard" component={Dashboard} />}

        <Route path="/" component={Home} />

        {/* <Route path="/*">
          <Redirect to="/" />
        </Route> */}
      </Switch>
    </Router>
  );
}

/**
 * namedRoutes
 *
 * Sometimes it is very important to name your routes if there's
 * a possibility of the routes changing often.
 *
 * This ensures that you have your routes defined at a central
 * place where you can update and have it reflect everywhere it
 * is used
 *
 * Usage
 *
 * import {namedRoutes} from "Routes";
 *
 * namedRoutes.home.index
 * namedRoutes.settings.profile
 * ....
 */
export const namedRoutes = {
  home: {
    legals: { terms_and_privacy: "/terms-and-privacy" },
    requirements: "/requirements",
    support: "/support",
    results: "/results",
    search: "/search",
    index: "/",
    completesetup: "/certificate-setup"
  },
  dashboard: {
    identification: "/dashboard/identification",
    delivery: "/dashboard/delivery/:step/step",
    certificate: "/dashboard/certificate",
    transaction: "/dashboard/transaction",
    wrongInfo: "/dashboard/wrong-info",
    verify: "/dashboard/verify",
    track: "/dashboard/track",
    index: "/dashboard",
  },
};
