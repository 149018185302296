import React, { useDispatch, Fragment, useGlobal, setGlobal } from "reactn";
import { Card } from "react-bootstrap";
import queryString from "query-string";

import { NeedHelp, Layout } from "Views/Components";
import { namedRoutes } from "Routes";
import { Button } from "Components";

export default function Profile({ history, location }) { 
  /**
   * variables
   */
  const params = queryString.parse(location.search);
  console.log(params)

  const [user] = useGlobal("user");
  //console.log('user', user)
  /**
   * dispatch
   */
  const logout = useDispatch("auth.logout");

  return (
    <Layout title="Details" className="dashboard-profile-page">
      <Card>
        <Card.Body className="px-md-40 py-md-5">
          <div className="d-flex align-items-center justify-content-between pb-20">
            <p className="header mb-0" style={{fontSize: 32}}>Hi, {user.fullname}</p>
            <NeedHelp />
          </div>
          <span className="underline" />

          <div className="content">
            <div className="content__user-data">
              <div className="data">
                <p className="data__header">Name</p>
                <p className="data__content">{user.fullname}</p>
              </div>

              <div className="data">
                <p className="data__header">University</p>
                <p className="data__content">
                  {user.institution}
                </p>
              </div>

              <div className="data">
                <p className="data__header">Place of Service</p>
                <p className="data__content">
                  {user.place}
                </p>
              </div>

              <div className="data">
                <p className="data__header">Qualification</p>
                <p className="data__content">
                  {user.qualification}
                </p>
              </div>
            </div>
            <div className="content__user-nss w-100">
              <div className="data">
                <p className="data__header">NSS Number</p>
                <p className="data__content">{user.nssno}</p>

                <Button
                  className="btn--success --h-56 w-100 mb-3"
                  value="Continue"
                  onClick={() =>{
                   // NSSGHS8573218
                    if(user.contact && user.contact?.idtypeid > 0 && user.contact?.idno?.length > 4){
                      //alert('ID already available');

                      if(parseInt(user.nssno.slice(-2)) >= 18){
                        setGlobal({identification: {...user.contact, idTypeNumber: user.contact.idtypeid, nssno: user.nssno }  });
                        return history.push(namedRoutes.dashboard.delivery.replace(":step", 1));
                        return history.push(namedRoutes.dashboard.verify)
                        return;
                      }
                      setGlobal({identification: {...user.contact, idTypeNumber: user.contact.idtypeid, nssno: user.nssno }  });
                      //setId({ ...identification, form: params }) |
                      // route the user to the ID verification page
                      return history.push(namedRoutes.dashboard.verify)
                    }else{
                      return history.push(namedRoutes.dashboard.identification)
                    }
                  }
                    
                  }
                />

                <Button
                  className="btn--stateless --h-56 w-100"
                  value={
                    <Fragment>
                      <i className="material-icons">keyboard_arrow_left</i>
                      <span className="d-block ml-2">Back</span>
                    </Fragment>
                  }
                  onClick={() =>
                    logout() |
                    history.push(
                      params?.from
                        ? params?.from === "home"
                          ? namedRoutes.home.index
                          : namedRoutes.home.results
                        : namedRoutes.home.index
                    )
                  }
                /> 
                <Button
                  //value="This is not my right details"
                  value="My personnel data is incorrect"
                  style={{marginTop:15}}
                  className="btn--danger-outlined --h-56 w-100"
                  onClick={() =>
                    history.push(namedRoutes.dashboard.wrongInfo)
                  }
                /> 
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Layout>
  );
}
