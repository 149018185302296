import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import passportBG from "Assets/img/photos/passport.png";
const Voters = ({ identification, user }) => (
 
  <table
    id="Table_01"
    width="530"
    height="340"
    border="0"
    cellPadding="0"
    cellSpacing="0"
  >
    <tbody>
      <tr>
        <td colSpan="13">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_01.gif"
            width="530"
            height="75"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colSpan="6">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_02.gif"
            width="210"
            height="28"
            alt=""
          />
        </td>
        <td colSpan="4">
          <a
            href="#"
            onmouseover="window.status='88393';  return true;"
            onmouseout="window.status='';  return true;"
          >
            <div
              style={{
                width: 132,
                height: 28,
                backgroundImage:
                  "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_06.gif)",
                fontWeight: "bold",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              {identification.PollingStation}{" "}
            </div>
          </a>
        </td>
        <td colSpan="2" rowSpan="5">
          <Image
            width={111}
            height={151}
            //src={`/img/id-${identication?.form?.type}.png`}
            src={
              identification?.Picture && identification?.Picture.length > 5 && identification?.Picture?.includes("gvive") ?
              `https://s3-eu-west-1.amazonaws.com/nss-doc/enrolment${user?.transcript?.enrolmentid}/${user?.nssno}/${identification.Picture.replace("gvivepassport/","")}`
              :
              identification?.Photo?.includes("gvive") ||
              identification?.Picture?.includes("gvive")
                ? `https://www.nsscertificate.com/${identification.Photo ||
                    identification.Picture}`
                : `data:image/png;base64, ${identification.Photo ||
                    identification.Picture}`
            }
            fluid
          />
        </td>
        <td rowSpan="8">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_05.gif"
            width="77"
            height="264"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colSpan="3" rowSpan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_06-07.gif"
            width="130"
            height="67"
            alt=""
          />
        </td>
        <td colSpan="5">
          <div
            style={{
              width: 156,
              height: 23,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_07.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            {identification.VoterID || identification.idno || ""}{" "}
          </div>
        </td>

        <td colSpan="2" rowSpan="4">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_08.gif"
            width="56"
            height="123"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colSpan="5">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_09.gif"
            width="156"
            height="44"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_10.gif"
            width="119"
            height="32"
            alt=""
          />
        </td>
        <td colSpan="3">
          <div
            style={{
              width: 83,
              height: 32,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_11.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            {identification.Age}{" "}
          </div>
        </td>
        <td colSpan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_12.gif"
            width="84"
            height="32"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowSpan="4">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_13.gif"
            width="118"
            height="137"
            alt=""
          />
        </td>
        <td colSpan="6">
          <div
            style={{
              width: 93,
              height: 24,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_14.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            {identification.Sex}{" "}
          </div>
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_15.gif"
            width="75"
            height="24"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colSpan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_16.gif"
            width="60"
            height="29"
            alt=""
          />
        </td>
        <td colSpan="5">
          <div
            style={{
              width: 114,
              height: 29,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_17.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            {identification.RegDate}{" "}
          </div>
        </td>
        <td colSpan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_18.gif"
            width="161"
            height="29"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowSpan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_19.gif"
            width="1"
            height="84"
            alt=""
          />
        </td>
        <td colSpan="9">
          <div
            style={{
              width: 244,
              height: 38,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_24.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            {identification.Fullname
              ? identification.Fullname
              : `${identification.firstname} ${identification.othername} ${identification.lastname}`}
          </div>
        </td>
        <td rowSpan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_21.gif"
            width="90"
            height="84"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colSpan="9">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/voter_id_22.gif"
            width="244"
            height="46"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="118"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="1"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="11"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="48"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="24"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="8"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="1"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="75"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img src="VOTERSimages/spacer.gif" width="6" height="1" alt="" />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="50"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="21"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="90"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_voters/spacer.gif"
            width="77"
            height="1"
            alt=""
          />
        </td>
      </tr>
    </tbody>
  </table>

);

const Passport0 = ({ identification, user }) => (
  <div
    style={{
      background: "url(/img/passport.png)",
      height: 366,
      width: 569,
      backgroundPosition: "center",
      backgroundSize: "contain"
    }}
  >
    <table>
      <tbody>
        <tr>
          <td rowspan="5" style={{border:"1px solid red"}}>
            <Image
              width={163}
              height={182}
              style={{ marginTop: "20%", marginLeft: "10%" }}
              //src={`/img/id-${identication?.form?.type}.png`}
              src={
                identification?.Picture && identification?.Picture.length > 5 && identification?.Picture?.includes("gvive") ?
                `https://s3-eu-west-1.amazonaws.com/nss-doc/enrolment${user?.transcript?.enrolmentid}/${user?.nssno}/${identification.Picture.replace("gvivepassport/","")}`
                :
                identification?.Photo?.includes("gvive") ||
                identification?.Picture?.includes("gvive")
                  ? `https://www.nsscertificate.com/${identification.Photo ||
                      identification.Picture}`
                
                      
                  : `data:image/png;base64, ${identification.Photo ||
                      identification.Picture}`
              }
              fluid
            />
          </td>
          <td rowspan="2" style={{border:"1px solid red"}}>
            <table>
              <tbody>
                <tr>
                  <td>jkghcjkbvkblkjvjblk;bljkvblk</td>
                </tr>
                <tr>
                  <td>lkjghc</td>
                </tr>
                <tr>
                  <td>jkgjfghcj</td>
                </tr>
                <tr>
                  <td>kvhjgcjvh</td>
                </tr>
                <tr>
                  <td>lkvhjkb</td>
                </tr>
                <tr>
                  <td>lhkugj</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td rowspan="2" style={{border:"1px solid red"}}>&nbsp;</td>
          <td rowspan="3" style={{border:"1px solid red"}}>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const Passport = ({ identification, user }) => (
    <div
    style={{
      background: "url(/img/passport.png)",
      height: 366,
      width: 569,
      backgroundPosition: "center",
      backgroundSize: "contain"
    }}
  >    
  <table
    id="Table_01"
    width="569"
    height="366"
    border="0"
    cellPadding="0"
    cellSpacing="0"
  >
    <tbody>
      <tr>
        <td colSpan="10" style={{height: 52}}>
          <img
            style={{display: "none"}}
            src="https://www.nsscertificate.com/images_passport/passport__01.gif"
            width="569"
            height="52"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowSpan="18">
          <img
            style={{display: "none"}}
            src="https://www.nsscertificate.com/images_passport/passport__02.gif"
            width="15"
            height={"100%"}
            alt=""
          />
        </td>
        <td rowSpan="15">
          <Image
            width={163}
            height={182}
            //src={`/img/id-${identication?.form?.type}.png`}
            src={
              identification?.Picture && identification?.Picture.length > 5 && identification?.Picture?.includes("gvive") ?
              `https://s3-eu-west-1.amazonaws.com/nss-doc/enrolment${user?.transcript?.enrolmentid}/${user?.nssno}/${identification.Picture.replace("gvivepassport/","")}`
              :
              identification?.Photo?.includes("gvive") ||
              identification?.Picture?.includes("gvive")
                ? `https://www.nsscertificate.com/${identification.Photo ||
                    identification.Picture}`
  
                : `data:image/png;base64, ${identification.Photo ||
                    identification.Picture}`
            }
            fluid
          />
        </td>
        <td rowSpan="2">
          <img
            style={{display: "none"}}
            src="https://www.nsscertificate.com/images_passport/passport__04.gif"
            width="8"
            height="24"
            alt=""
          />
        </td>
        <td>
          <div
            style={{
              width: 61,
              height: 16,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__05.gif)",
              fontWeight: "bold",
              fontSize: 13
            }}
          >
            TYPE
          </div>
        </td>
        <td rowSpan="2">
          <img
            src="https://www.nsscertificate.com/images_passport/passport__06.gif"
            width="19"
            height={"100%"}
            alt=""
            style={{display: "none"}}
          />
        </td>
        <td>
          <div
            style={{
              width: 101,
              height: 16,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__07.gif)",
              fontWeight: "bold",
              fontSize: 13
            }}
          >
            GH
          </div>
        </td>
        <td colSpan="2" rowSpan="12">
          <img
            src="https://www.nsscertificate.com/images_passport/passport__08.gif"
            width="45"
            height={"100%"}
            alt=""
            style={{display: "none"}}
          />
        </td>
        <td>
          <div
            style={{
              width: 127,
              height: 16,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__09.gif)",
              fontWeight: "bold",
              fontSize: 13
            }}
          >
            {identification.PassportNo || identification.idno || ""}{" "}
          </div>
        </td>
        <td rowSpan="18">
          <img
            src="https://www.nsscertificate.com/images_passport/passport__10.gif"
            width="30"
            height={"100%"}
            alt=""
            style={{display: "none"}}
          />
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="https://www.nsscertificate.com/images_passport/passport__11.gif"
            width="61"
            height={"100%"}
            alt=""
            style={{display: "none"}}
          />
        </td>
        <td>
          <img
            src="https://www.nsscertificate.com/images_passport/passport__12.gif"
            width="101"
            height={"100%"}
            alt=""
            style={{display: "none"}}
          />
        </td>
        <td rowSpan="11">
          <img
            src="https://www.nsscertificate.com/images_passport/passport__13.gif"
            width="127"
            height={"100%"}
            alt=""
            style={{display: "none"}}
          />
        </td>
      </tr>
      <tr>
        <td colSpan="4">
          <div
            style={{
              width: 189,
              height: 15,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__14.gif)",
              fontWeight: "bold",
              fontSize: 13
            }}
          >
            {identification.LastName || identification.lastname}{" "}
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="4">
          <img
            src="https://www.nsscertificate.com/images_passport/passport__15.gif"
            width="189"
            height={"100%"}
            alt=""
            style={{display: "none"}}
          />
        </td>
      </tr>
      <tr>
        <td colSpan="4">
          <div
            style={{
              width: 189,
              height: 15,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__16.gif)",
              fontWeight: "bold",
              fontSize: 13
            }}
          >
            {identification.FirstName || identification.firstname || ""}{" "}
            {identification.MiddleName || identification.middlename || ""}{" "}
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="4">
          <img
            src="https://www.nsscertificate.com/images_passport/passport__17.gif"
            width="189"
            height={"100%"}
            alt=""
            style={{display: "none"}}
          />
        </td>
      </tr>
      <tr>
        <td colSpan="4">
          <div
            style={{
              width: 189,
              height: 13,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__18.gif)",
              fontWeight: "bold",
              fontSize: 11
            }}
          >
            {identification.Nationality || ""}{" "}
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="4" style={{height: 9}}>
          <img
            src="https://www.nsscertificate.com/images_passport/passport__19.gif"
            width="189"
            height="9"
            alt=""
            style={{display: "none"}}
          />
        </td>
      </tr>
      <tr>
        <td colSpan="4">
          <div
            style={{
              width: 189,
              height: 16,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__20.gif)",
              fontWeight: "bold",
              fontSize: 11
            }}
          >
            {identification.DateOfBirth}{" "}
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="4" style={{height: 8}}>
          <img
            src="https://www.nsscertificate.com/images_passport/passport__21.gif"
            width="189"
            height="8"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <div
            style={{
              width: 69,
              height: 13,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__22.gif)",
              fontWeight: "bold",
              fontSize: 11
            }}
          >
            {identification.Gender}{" "}
          </div>
        </td>
        <td colSpan="2">
          <div
            style={{
              width: 120,
              height: 13,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__23.gif)",
              fontWeight: "bold",
              fontSize: 11
            }}
          >
            {identification.PlaceOfBirth || identification.DateOfBirth || ""}{" "}
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="4" style={{height: 8}}>
          <img
            src="https://www.nsscertificate.com/images_passport/passport__24.gif"
            width="189"
            height="8"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colSpan="4">
          <div
            style={{
              width: 189,
              height: 17,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__25.gif)",
              fontWeight: "bold",
              fontSize: 11
            }}
          >
            {identification.IssueDate}{" "}
          </div>
        </td>
        <td rowSpan="6">
          <img
            src="https://www.nsscertificate.com/images_passport/passport__26.gif"
            width="26"
            height={"100%"}
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td colSpan="2">
          <div
            style={{
              width: 146,
              height: 17,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__27.gif)",
              fontWeight: "bold",
              fontSize: 11
            }}
          >
            {identification.PlaceOfIssue}{" "}
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="4" style={{height: 9}}>
          <img
            src="https://www.nsscertificate.com/images_passport/passport__28.gif"
            width="189"
            height="9"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td colSpan="2">
          <img
            src="https://www.nsscertificate.com/images_passport/passport__29.gif"
            width="146"
            height={"100%"}
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colSpan="4">
          <div
            style={{
              width: 189,
              height: 17,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__30.gif)",
              fontWeight: "bold",
              fontSize: 11
            }}
          >
            {identification.ExpiryDate}{" "}
          </div>
        </td>
        <td colSpan="2">
          <div
            style={{
              width: 146,
              height: 17,
              backgroundImage:
                "url(https://www.nsscertificate.com/images_passport/passport__31.gif)",
              fontWeight: "bold",
              fontSize: 11
            }}
          >
            Authority
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="5" style={{height: 13}}>
          <img
            src="https://www.nsscertificate.com/images_passport/passport__32.gif"
            width="352"
            height="13"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td colSpan="2" rowSpan="3">
          <img
            src="https://www.nsscertificate.com/images_passport/passport__33.gif"
            width="146"
            height={"100%"}
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td>
          <Image
            width={163}
            height={61}
            //src={`/img/id-${identication?.form?.type}.png`}
            //src={`data:image/png;base64, ${identification.Signature}`}
            src={
              
              identification?.Signature?.includes("gvive")
                ? `https://www.nsscertificate.com/${identification.Signature}`

                : `data:image/png;base64, ${identification.Signature}`
            }
          />
        </td>
        <td colSpan="4" rowSpan="2" style={{height: 118}}>
          <img
            src="https://www.nsscertificate.com/images_passport/passport__35.gif"
            width="189"
            height="118"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td style={{height: 57}}>
          <img
            src="https://www.nsscertificate.com/images_passport/passport__36.gif"
            width="163"
            height="57"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="15"
            height="1"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="163"
            height="1"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="8"
            height="1"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="61"
            height="1"
            alt=""
            style={{display: "none"}}
          />
        </td>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="19"
            height="1"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="101"
            height="1"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="26"
            height="1"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="19"
            height="1"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="127"
            height="1"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{height: 1}}>
          <img
            src="https://www.nsscertificate.com/images_passport/spacer.gif"
            width="30"
            height="1"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>
);

const SSNIT = ({ identification, user }) => (
  <table
    id="Table_01"
    width="661"
    height="414"
    border="0"
    cellpadding="0"
    cellspacing="0"
  >
    <tbody>
      <tr>
        <td colspan="7">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_01.gif"
            width="660"
            height="113"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="1"
            height="113"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_02.gif"
            width="456"
            height="68"
            alt=""
          />
        </td>
        <td colspan="2" rowspan="3">
          <Image
            width={192}
            height={200}
            //src={`/img/id-${identication?.form?.type}.png`}
            src={
               identification?.Picture && identification?.Picture.length > 5 && identification?.Picture?.includes("gvive") ?
              `https://s3-eu-west-1.amazonaws.com/nss-doc/enrolment${user?.transcript?.enrolmentid}/${user?.nssno}/${identification.Picture.replace("gvivepassport/","")}`
              :
              identification?.Photo?.includes("gvive") ||
              identification?.Picture?.includes("gvive")
                ? `https://www.nsscertificate.com/${identification.Photo ||
                    identification.Picture}`

                : `data:image/png;base64, ${identification.Photo ||
                    identification.Picture}`
            }
          />
        </td>

        <td rowspan="4">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_04.gif"
            width="12"
            height="247"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="1"
            height="68"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="2" rowspan="4">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_05.gif"
            width="189"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td colspan="2">
          <div
            style={{
              width: 267,
              height: 39,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_06.gif)",
              fontWeight: "bold",
              fontSize: 13
            }}
          >
            {identification.FSSNo || identification.idno}{" "}
          </div>
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="1"
            height="39"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="2" rowspan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_07.gif"
            width="267"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="1"
            height="93"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_08.gif"
            width="192"
            height="47"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="1"
            height="47"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowspan="4">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_09.gif"
            width="73"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td colspan="2" rowspan="2">
          <div
            style={{
              width: 131,
              height: "100%",
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_10.gif)",
              fontWeight: "bold",
              fontSize: 13
            }}
          >
            {identification.Sex || identification.Gender || ""}{" "}
          </div>
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="1"
            height="14"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowspan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_11.gif"
            width="75"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td colspan="2" rowspan="2">
          <div
            style={{
              width: 193,
              height: "100%",
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_12.gif)",
              fontWeight: "bold",
              fontSize: 13
            }}
          >
            {identification.CardSerial}{" "}
          </div>
        </td>
        <td rowspan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_13.gif"
            width="188"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="1"
            height="9"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="2" rowspan="2">
          <div
            style={{
              width: 131,
              height: "100%",
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_14.gif)",
              fontWeight: "bold",
              fontSize: 13
            }}
          >
            {identification.BirthDate || identification.DateOfBirth || ""}{" "}
          </div>
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="1"
            height="18"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/ssnit_15.gif"
            width="193"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="75"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="114"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="79"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="188"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="73"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="119"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_ssnit/spacer.gif"
            width="12"
            height="1"
            alt=""
          />
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
);

const Drivers = ({ identification, user }) => (
  <div
  style={{
    background: "url(/img/dvlacard.jpg)",
    height: 366,
    width: 569,
    backgroundPosition: "center",
    backgroundSize: "contain"
  }}
> 
  <table
    id="Table_01"
    width="574"
    height="368"
    border="0"
    cellpadding="0"
    cellspacing="0"
  >
    <tbody>
      <tr>
        <td colspan="15" style={{heigth:26}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_01.gif"
            width="573"
            height="26"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{heigth:26}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            height="26"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowspan="16">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_02.gif"
            width="20"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td rowspan="8">
          <Image
            width={175}
            height={229}
            //src={`/img/id-${identication?.form?.type}.png`}
            //src={`data:image/png;base64, ${identification.DriverImage}`}
            src={
              identification?.Picture && identification?.Picture.length > 5 && identification?.Picture?.includes("gvive") ?
              `https://s3-eu-west-1.amazonaws.com/nss-doc/enrolment${user?.transcript?.enrolmentid}/${user?.nssno}/${identification.Picture.replace("gvivepassport/","")}`
              :
              identification?.Photo?.includes("gvive") ||
              identification?.Picture?.includes("gvive")
                ? `https://www.nsscertificate.com/${identification.Photo ||
                    identification.Picture}`
                : `data:image/png;base64, ${identification.Photo ||
                    identification.Picture || identification.DriverImage}`
            }
          />
        </td>
        <td colspan="13" style={{heigth:65}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_04.gif"
            width="378"
            height="65"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{heigth:65}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            height="65"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="9">
          <div
            style={{
              width: 284,
              height: 32,
              marginTop: 66,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_05.gif)",
              fontWeight: "bold",
              fontSize: 15
            }}
          >
            <p> {identification.Name}</p>
            <p>&nbsp;</p>
          </div>
        </td>
        <td colspan="4" rowspan="2" style={{heigth:49}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_06.gif"
            width="94"
            height="49"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{heigth:32}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            height="32"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr style={{heigth:17}}>
        <td colspan="9">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_07.gif"
            width="284"
            height="17"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td style={{height:17}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            height="17"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <div
            style={{
              width: 143,
              height: 31,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_08.gif)",
              fontWeight: "bold",
              fontSize: 15
            }}
          >
            {identification.DateOfBirth}{" "}
          </div>
        </td>
        <td colspan="3" rowspan="4" style={{height: 98}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_09.gif"
            width="38"
            height="98"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td colspan="5">
          <div
            style={{
              width: 187,
              height: 31,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_10.gif)",
              fontWeight: "bold",
              fontSize: 15
            }}
          >
            {identification.PIN}{" "}
          </div>
        </td>
        <td rowspan="13">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_11.gif"
            width="10"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td style={{height: 31}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            height="31"
            style={{display: "none"}}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="4" style={{height: 27}}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_12.gif"
            width="143"
            height="27"
            style={{display: "none"}}
            alt=""
          />
        </td>
        <td colspan="5" rowspan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_13.gif"
            width="187"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://www.nsscertificate.com/images/spacer.gif"
            width="1"
            height="27"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <div
            style={{
              width: 143,
              height: 30,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_14.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            {identification.ProcessingCenter}{" "}
          </div>
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_15.gif"
            width="143"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowspan="4">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_16.gif"
            width="8"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td colspan="2" rowspan="2">
          <div
            style={{
              width: 118,
              height: "100%",
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_17.gif)",
              fontWeight: "bold",
              fontSize: 15
            }}
          ></div>
        </td>
        <td colspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_18.gif"
            width="29"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td colspan="4">
          <div
            style={{
              width: 129,
              height: 17,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_19.gif)",
              fontWeight: "bold",
              fontSize: 15
            }}
          >
            {identification.Nationality}{" "}
          </div>
        </td>
        <td colspan="3" rowspan="4">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_20.gif"
            width="84"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowspan="5">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_220.gif"
            width="175"
            style={{ height: "100%" }}
            alt="signature image"
          />
        </td>
        <td colspan="3" rowspan="2">
          <div
            style={{
              width: 47,
              height: 31,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_22.gif)",
              fontWeight: "bold",
              fontSize: 15
            }}
          >
            {identification.ClassOfLicence}{" "}
          </div>
        </td>
        <td colspan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_23.gif"
            width="111"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="2" rowspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_24.gif"
            width="118"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td colspan="3" rowspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_25.gif"
            width="111"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_26.gif"
            width="47"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div
            style={{
              width: 109,
              height: "100%",
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_27.gif)",
              fontWeight: "bold",
              fontSize: 15
            }}
          >
            {identification.DateOfIssue}{" "}
          </div>
        </td>
        <td colspan="6">
          <div
            style={{
              width: 98,
              height: 23,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_28.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            {identification.ExpiryDate}{" "}
          </div>
        </td>
        <td colspan="3">
          <div
            style={{
              width: 147,
              height: 23,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_29.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            1970-01-01{" "}
          </div>
        </td>
        <td rowspan="5">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_30.gif"
            width="14"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="11" rowspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_31.gif"
            width="354"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowspan="3">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_32.gif"
            width="175"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td rowspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_33.gif"
            width="8"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td colspan="2">
          <div
            style={{
              width: 118,
              height: "100%",
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_34.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            {identification.CertificateDate}{" "}
          </div>
        </td>
        <td colspan="2" rowspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_35.gif"
            width="29"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td colspan="5">
          <div
            style={{
              width: 162,
              height: 21,
              backgroundImage:
                "url(https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_36.gif)",
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            {identification.CertificateOfCompetence}{" "}
          </div>
        </td>
        <td rowspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_37.gif"
            width="37"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_38.gif"
            width="118"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td colspan="5">
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/dvla_39.gif"
            width="162"
            style={{ height: "100%" }}
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="1"
            height="9"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="20"
            height="1"

            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="175"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="8"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="101"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="17"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="17"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="12"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="18"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="8"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="26"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="77"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="33"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="37"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="14"
            height="1"
            alt=""
          />
        </td>
        <td>
          <img
            src="https://s3.eu-west-1.amazonaws.com/nsscertificate.com/ids/images_dvlaa/spacer.gif"
            width="10"
            height="1"
            alt=""
          />
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>
);

const IDCard = ({ identification, user }) => {

  try{
    console.log({ identification });
    if (identification.idTypeNumber === 1)
      return <Voters identification={identification} user={user} />;
    if (identification.idTypeNumber === 2)
      return <Passport identification={identification}  user={user} />;
    if (identification.idTypeNumber === 3)
      return <Drivers identification={identification} user={user} />;
    if (identification.idTypeNumber === 4)
      return <SSNIT identification={identification} user={user} />;
  
    return <p>Invalid ID Type</p>;
  }catch(e){
    return <p>Invalid ID Type</p>;
  }

};

export default IDCard;
