/* eslint-disable no-unreachable */
import React, {
  useGlobal,
  setGlobal,
  useState,
  useEffect,
  useDispatch
} from "reactn";
import { Col, Row, Form, Image } from "react-bootstrap";
import { Formik } from "formik";
import countryList from "react-select-country-list";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { Field, Button, Select, countryPhoneCode } from "Components";
import { SideCard, PersonnelInfo } from "Views/Components";
import { namedRoutes } from "Routes";
import axios from "axios";

import { BASE_URL } from "../../../Config";
import Certificate from "../Certificate";
import { logout } from "Reducers/auth";
//import PhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/lib/style.css'
import "react-phone-number-input/style.css";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
//import './style.css';
import acceptedValues from "./acceptedValues";
import _ from "lodash";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber
} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

const { compose, withProps, lifecycle } = require("recompose");
const MapWithASearchBox = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCkADRfp9Em4KxU9GkKlqLwrgUNjBnfhv4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    //containerElement: <div style={{ height: `60px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.props.setThis(this);

      this.setState({
        bounds: null,
        zoom: 13,
        searchTerm: "xxx",
        center: {
          lat: parseFloat(7.9465),
          lng: parseFloat(-1.023194)
        },
        markers: [],
        onMapMounted: ref => {
          refs.map = ref;
        },
        setSearchTerm: e => {
          this.setState({
            searchTerm: e.target.value
          });
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter()
          });
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        setPlace(nextCenter, nextMarkers) {
          this.setState({
            center: nextCenter,
            markers: nextMarkers
          });
        },
        onPlacesChanged: () => {
          console.log("onPlacesChanged");

          const places = refs.searchBox.getPlaces();
          // eslint-disable-next-line no-undef
          const bounds = new google.maps.LatLngBounds();
          console.log({ bounds, places });

          const lat = places[0].geometry.location.lat();
          const lng = places[0].geometry.location.lng();
          console.log({ lat, lng });

          this.props.mapClicked({ lat, lng, place: places[0] }); 

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location
          }));
          const nextCenter = _.get(
            nextMarkers,
            "0.position",
            this.state.center
          );

          this.setState({
            center: nextCenter,
            markers: nextMarkers
            //searchTerm: places[0] ? places[0].name : this.state.searchTerm
          });
          // refs.map.fitBounds(bounds);
        }
      });
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    ref={props.onMapMounted}
    onClick={event => {
      console.log({ event });
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      //console.log({ lat, lng });

      console.log("ON MAP CLICK DIABLED")

      //props.mapClicked({ lat, lng });
    }}
    defaultZoom={7}
    zoom={props.zoom}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}
    //defaultCenter={{ lat: parseFloat(7.9465), lng: parseFloat(-1.023194) }}
    defaultOptions={{ mapTypeControl: false }}
    //mapTypeControlOptions: {
    //mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
    //}, // here´s the array of controls
  >
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      // eslint-disable-next-line no-undef
      controlPosition={google.maps.ControlPosition.TOP}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Search Your Address"
        //value={props.searchTerm}
        //onChange={e => console.log({ searchBox: props.searchTerm })}
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `80%`,
          height: `50px`,
          marginLeft: `-40px`,
          marginTop: `27px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`
        }}
      />
    </SearchBox>
    {props.markers.map((marker, index) => (
      <Marker key={index} position={marker.position} />
    ))}
  </GoogleMap>
));

export const Schema = Yup.object({
  country: Yup.string().required(),
  /*digit_address: Yup.string()
      .nullable()
      .max(15, "Ghana Post GPS Address length is less than 15"),*/
  digit_address: Yup.string().typeError("Ghana Post Address is required")
  /*.test(
    "Digital Address Test Test",
    "Invalid Digital Address. Use the map to pick a valid address",
    value => {
      console.log({ value });
      if(!value || value.length < 9){ 
        return false;
      }

      //if(count)
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://dgxpac311k64y.cloudfront.net/src/functions/php/verifyGhanaPostGPS.php?address=${value.replace(
              "-",
              ""
            )}`
          )
          .then(({ data }) => {
            console.log({ data });
            if (!data || data === "null") {
              resolve(false);
              return;
            }
            resolve(true);
          })
          .catch(e => {
            resolve(false);
            console.error(e);
          });
      });
    }
  )*/
});

export default function Info({ history, next }) {
  const [deliveryData, setDeliveryData] = useGlobal("deliveryData");

  const [user] = useGlobal("user");
  console.log(user);

  const [loading, setLoading] = useState(true);
  console.log({ loading });

  const [loadingGPS, setLoadingGPS] = useState(false);
  console.log({ loadingGPS });

  const [cities, setCities] = useState([]);
  console.log({ cities });

  const [theThis, setThis] = useState(null);
  console.log({ theThis });

  const [notIncludesError, setNotIncludesError] = useState(false);
  console.log({ notIncludesError });

  const [countries, setCountries] = useState([]);
  console.log({ countries });

  const [selectedPlace, setSelectedPlace] = useState({});
  console.log({ selectedPlace });

  const logout = useDispatch("auth.logout");

  const [userToTypeAddressThemselves] = useGlobal(
    "userToTypeAddressThemselves"
  );
  console.log({ userToTypeAddressThemselves });

  useEffect(() => {
    // Update the document title using the browser API

    /*return history.push(
      namedRoutes.dashboard.delivery.replace(":step", next)
    );*/

    setGlobal({ userToTypeAddressThemselves: false });
    //console.log("hi");
    Promise.all([fetchCountries()]).then(([countries]) => {
      console.log({ countries });
      //setCities(cities);
      setCountries(countries);
      setLoading(false);
    });
  }, []);

  const fetchCities = async _ => {
    return axios.get(`${BASE_URL}/api/cities`).then(({ data }) => data.cities);
    try {
      const response = await axios.get(`${BASE_URL}/api/cities`);
      const { cities: ct } = response.data;
      setCities(ct);
      console.log("citieess", cities);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchCountries = async _ => {
    return axios
      .get(`${BASE_URL}/api/countries`)
      .then(({ data }) => data.countries);
  };

  const verifyPOSTGPS = async digit_address => {
    return axios.get(
      `https://api-dev.nssghdev.com/svc/enrolment/api/ghana-post-gps/get-location/${digit_address.replaceAll(
        "-",
        ""
      )}`
    );
  };

  const saveTranscriptDetails = async ({
    digitaddress,
    countryid,
    region,
    closest_landmark,
    nssno,
    dob,
    address1,
    address2,
    area_neighbourhood,
    gps_latitude = "",
    gps_longitude = ""
  }) => {
    return axios.post(`${BASE_URL}/api/apply-for-certificate/step-1`, {
      digitaddress,
      countryid,
      region,
      closest_landmark,
      nssno,
      dob,
      address1,
      address2,
      area_neighbourhood,
      gps_latitude,
      gps_longitude
    }).catch(e => {
      console.error('Step 1 call error:', e);
      if(e.response.data.message) alert(e.response.data.message);
    });
    /*.then(res => {
      setSubmitting(false);
      console.log(res);

      const { transcript, invoice, link = null } = res.data;
      //setGlobal({ user: {...user, transcript, invoice, cert: { link } } });
      setGlobal({ user: { ...user, transcript, invoice } });
      setDeliveryData({
        ...deliveryData,
        personnel: { ...transcript }
      }); 
      return history.push(
        namedRoutes.dashboard.delivery.replace(":step", next)
      );
    })
    .catch(e => {
      setSubmitting(false);

      console.log(e.response);
      if (
        e.response &&
        e.response.status >= 400 &&
        e.response.data.message
      ) {
        alert(e.response.data.message);
        setErrors({ phone: e.response.data.message });
      }
    });*/
  };

  const saveUserCountry = ({ nssno, countryid, dob }) => {
    return axios.post(`${BASE_URL}/api/apply-for-certificate/save-country`, {
      countryid,
      nssno,
      dob
    });
  };

  return (
    <div>
      <h1 className="mb-4 mb-md-5 font-size-18 font-size-md-24 text-center text-md-left">
        Ex NSS Personnel Delivery Details.
      </h1>

      <Row className="justify-content-between">
        <Col lg="7">
          {loading ? (
            <div
              style={{ textAlign: "center", paddingTop: "20%", height: "100%" }}
            >
              <Image src={`/img/load.svg`} />
            </div>
          ) : (
            <Formik
              initialValues={{
                country: deliveryData?.personnel?.country || "gh",
                digit_address: user?.transcript?.digitaddress,

                countryid: user?.transcript?.countryid,
                countries
              }}
              validationSchema={Schema}
              children={props => (
                <InfoForm
                  {...props}
                  LoadingGPS={loadingGPS}
                  setLoadingGPS={setLoadingGPS}
                  setThis={setThis}
                  theThis={theThis}
                  history={history}
                  user={user}
                  setNotIncludesError={setNotIncludesError}
                  notIncludesError={notIncludesError}
                  selectedPlace={selectedPlace}
                  setSelectedPlace={setSelectedPlace}
                />
              )}
              onSubmit={async (params, { setSubmitting, setErrors }) => {
                /**
                 * It would make sense to push the data to a global state
                 */
                /*setDeliveryData({ ...deliveryData, personnel: { ...params } });
                history.push(
                  namedRoutes.dashboard.delivery.replace(":step", next)
                );*/

                const { digit_address: digitaddress, countryid } = params;

                if (countryid !== 1) {
                  // save country
                  const { data } = await saveUserCountry({
                    nssno: user.nssno,
                    dob: user.dob,
                    countryid
                  });

                  const { transcript } = data;
                  setGlobal({ user: { ...user, transcript } });
                  return history.push(
                    namedRoutes.dashboard.delivery.replace(":step", next)
                  );
                }

                const digital_address_response = await verifyPOSTGPS(
                  digitaddress
                );

                //setSubmitting(false);
                console.log({ digital_address_response });
                if (
                  !digital_address_response.data ||
                  digital_address_response.data === "null"
                ) {
                  //resolve(false);
                  setErrors({ digit_address: "Invalid Digit Address " });
                  return;
                }

                const {
                  //Area,
                  //GPSName: closest_landmark,
                  //postCode: Area,
                  AddressV1: digitaladdress_v1,
                  Area,
                  //streetName: Street,
                  Street,
                  //district,
                  District:district,
                  //region,
                  Region:region,
                  CenterLatitude: lat,
                  CenterLongitude: lng
                } = digital_address_response.data.Table[0];

                const address1 = `${Area}, ${Street}`;
                //const address2 = `${Area}, ${Street}`;
                const address2 = `${selectedPlace.name || Street}, Ghana`;

                const res = await saveTranscriptDetails({
                  digitaddress: digitaladdress_v1,
                  countryid,
                  region,
                  closest_landmark: `${district}`,
                  nssno: user.nssno,
                  dob: user.dob || "1990-01-01",
                  address1,
                  address2,
                  area_neighbourhood: Area,
                  gps_latitude: lat,
                  gps_longitude: lng
                })

                setSubmitting(false);
                console.log(res);

                const { transcript } = res.data;
                //setGlobal({ user: {...user, transcript, invoice, cert: { link } } });
                setGlobal({ user: { ...user, transcript } });
                /*setDeliveryData({
                ...deliveryData,
                personnel: { ...transcript }
                }); */
                return history.push(
                  namedRoutes.dashboard.delivery.replace(":step", next)
                );

                /*setGlobal({ user: {
                        ...user, 
                        area: Area, 
                        region: Region, 
                        landmark: GPSName,
                        digit_address: GPSName,
                        addresses: {
                            main: `${Area}, ${Street}, ${District}`,
                            others: `${Area}, ${Street}, ${District}`
                        }
                    }});
                    setGlobal({ gps_result: { ...data } });
                    setDeliveryData({
                      ...deliveryData,
                      personnel: { ...transcript }
                    });
                     
                    return history.push(
                      namedRoutes.dashboard.delivery.replace(":step", next)
                    );*/
              }}
            />
          )}
        </Col>
        <Col lg="4">
          <SideCard header="Summary">
            <PersonnelInfo>
              <PersonnelInfo.Data header="Name" content={user.fullname} />
              <PersonnelInfo.Data header="School" content={user.institution} />
              <PersonnelInfo.Data
                header="NSS Number"
                content={user.nssno}
                contentCss="font-size-24"
              />
              <PersonnelInfo.Data header="Company" content={user.place} />
            </PersonnelInfo>
          </SideCard>
        </Col>
      </Row>
    </div>
  );
}

export function InfoForm(props) {
  console.log({ props });
  const {
    history,
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue,
    setLoadingGPS,
    setNotIncludesError,
    notIncludesError,
    LoadingGPS,
    setErrors,
    setFieldError,
    setSelectedPlace,
    selectedPlace,
    theThis,
    user,
    setThis
  } = props;
  const {
    country,
    digit_address,
    email,
    phone,
    addresses,
    area,
    landmark,
    city,
    cityid,
    countryid,
    region,
    cities,
    countries
  } = values;
  console
    .log
    // countries.find(country => country.country_id == countryid).country_code
    ();
  return (
    <div>
      {LoadingGPS ? (
        <Row>
          <Col md="12">
            <div
              style={{
                position: "absolute",
                //right: 30,
                //top: "10%",
                zIndex: 1000,
                height: "510px",
                //marginRight: 15,
                borderRadius: 4,
                textAlign: "center",
                paddingTop: "35%",
                paddingBottom: "auto",
                width: "95%",
                opacity: 0.7,
                backgroundColor: "black"
              }}
            >
              <Image
                height="50"
                width="50"
                //style={}
                src={`/img/load.svg`}
              />
            </div>
          </Col>
        </Row>
      ) : null}
      <Form className="mb-5 mb-lg-0">
        <Row>
          <Col md="12">
            <Row>
              <Col md="12">
                <Field
                  name="countryid"
                  //value={country || ""}
                  value={`${countryid || ""}`}
                  label={{ value: "Country" }}
                  component={Select}
                  //onlyOptions={["gh", "us", "ru", "cn", "gb"]}
                  //options={countryList().getData()}
                  options={countries.map(country => {
                    return {
                      value: country.country_id,
                      label: country.country
                    };
                  })}
                  className="px-0 --border-none"
                  placeholder=""
                  onChange={item => setFieldValue("countryid", item.value)}
                />
              </Col>

              <Col md="12"></Col>
            </Row>
          </Col>
        </Row>

        {countryid === 1 ? (
          <Row style={{ marginBottom: 15 }}>
            <Col md="12">
              <Row>
                <Col md="12">
                  <Field
                    name="digit_address"
                    value={digit_address || ""}
                    onChange={e => {
                      const { value } = e.target;
                      console.log({ value });
                      
                      if (!value || value.length < 9) {
                        //setErrors({ digit_address: "In"})
                        setFieldValue("digit_address", value);
                        return;
                      }
                      if (!acceptedValues.includes(value.substring(0, 2))) {
                        console.log("NOT INCLUDES");
                        //setFieldError("digit_address","NOT INCLUDES");
                        setNotIncludesError(true);
                        setFieldValue("digit_address", value);
                        return;
                      }else setNotIncludesError(false);
                      setLoadingGPS(true);
                      console.log({ e: value });
                      setFieldValue("digit_address", value);
                      axios
                        .get(
                          `https://api-dev.nssghdev.com/svc/enrolment/api/ghana-post-gps/get-location/${value.replaceAll(
                            "-",
                            ""
                          )}`
                        )
                        .then(({ data }) => {
                          setLoadingGPS(false);
                          console.log({ data });
                          if (!data || data === "null") {
                            //resolve(false);
                            setErrors({
                              digit_address: "Invalid Digital Address "
                            });
                            return;
                          }

                          /*const {
                            Area,
                            GPSName,
                            Street,
                            District,
                            Region,
                            CenterLatitude: lat,
                            CenterLongitude: lng
                          } = data;
                          console.log({
                            Area,
                            GPSName,
                            Street,
                            District,
                            Region
                          });*/
                          console.log({data: data.Table})
                          const { District:district, CenterLatitude, CenterLongitude, lngLat=null, lngLat2=null, PostCode:postCode, Region:region, Street:streetName } = data.Table[0];
                          console.log({ district, CenterLatitude, CenterLongitude, lngLat, lngLat2, postCode, region, streetName });
                          setLoadingGPS(false);
                          setFieldValue("digit_address", value);

                          let lat = null;
                          let lng = null;
                          if(lngLat2){
                            let [lat, lng] = lngLat2.split(",");
                          }else{
                            lat = CenterLatitude;
                            lng = CenterLongitude;
                          }
                          
                          console.log({ lng, lat });
                          lat = parseFloat(lat);
                          lng = parseFloat(lng);
                          console.log({ lng, lat });
                          /*setFieldValue(
                    "addresses.main",
                    `${Area}, ${Street}, ${District}`
                  );
                  setFieldValue(
                    "addresses.others",
                    `${Area}, ${Street}, ${District}`
                  );*/
                          /*setFieldValue("area", Area);
                  setFieldValue("region", Region);
                  setFieldValue("landmark", GPSName);*/

                          theThis.setState({
                            center: { lat, lng },
                            markers: [{ position: { lat, lng } }],
                            zoom: 17
                            //searchTerm: Street
                          });
                          //resolve(true);
                        })
                        .catch(e => {
                          //resolve(false);
                          setLoadingGPS(false);
                        });
                    }}
                    label={{
                      value: `Ghana Post GPS Address - This is my Home/House/Office address (Permanent place of residence or work) ${LoadingGPS ? "" : ""}`
                    }}
                  />
                  { notIncludesError ? <span style={{position:"absolute",bottom: 15, color:"red"}}>Invalid Digital Address Format </span> : null }
                  
                  <p style={{fontSize:12}}>If you dont know your digital address, get it <a href="https://www.ghanapostgps.com/map/" target="_blank">HERE</a></p>
                </Col>
                
              </Row>
               
            </Col>
            
          </Row>
        ) : null}

        <Row style={{ marginTop: 30 }}>
          <Col md="6" lg="5">
            <Button
              value="Confirm & Continue"
              className="btn--success --h-56 w-100"
              onClick={handleSubmit}
              type="submit"
              disabled={LoadingGPS}
              isSubmitting={isSubmitting}
            />
          </Col>
          { user.cert_type === 1 ? <Col md="6" lg="5">
            {countryid === 1 ? (
              <Button
                value="I'll Type my address"
                className=" --h-56 w-100"
                style={{ color: "#2FAEE5" }}
                onClick={() => {
                  setGlobal({ userToTypeAddressThemselves: true });
                  return history.push(
                    namedRoutes.dashboard.delivery.replace(":step", 2)
                  );
                }}
                type="button"
                disabled={LoadingGPS} 
                isSubmitting={isSubmitting}
              />
            ) : null}
          </Col> : null }
        </Row>
      </Form>
    </div>
  );
}
