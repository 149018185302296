import React, { useGlobal, useDispatch, Fragment } from "reactn";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import { namedRoutes } from "Routes";
import { useTitle } from "Utils";

import clearingHubImage from "Assets/img/site/clearinghub.png";
import logoutIcon from "Assets/img/icons/logout.svg";
import logoImage from "Assets/img/site/logo.png";
import userIcon from "Assets/img/icons/user.svg";

export default function Layout({ title, children, className }) {
  useTitle(title || "");

  /**
   * state
   */
  const [user] = useGlobal("user");
  const [isAuthenticated] = useGlobal("isAuthenticated");

  /**
   * dispatch
   */
  const logout = useDispatch("auth.logout");

  return (
    <Fragment>
      <nav className="navigation">
        <Container>
          <div className="navigation__container px-3 px-md-0">
            <Link
              className="logo"
              to={
                isAuthenticated
                  ? namedRoutes.dashboard.index
                  : namedRoutes.home.index
              }
            >
              <Image className="img-fluid logo__img" src={logoImage} />
            </Link>
            {isAuthenticated && (
              <div className="user">
                <p className="user__name">Hello {user.fullname ? user.fullname: 'Eldad'}</p>
                <div className="user__image">
                  <Image src={  user && user?.avatar?.length > 10 ? user.avatar :  userIcon } />
                </div>
                <span className="user__logout pointer" onClick={() => logout()}>
                  <Image src={logoutIcon} />
                </span>
              </div>
            )}
          </div>
        </Container>
      </nav>
      <div className={`body ${className}`}>
        <Container>{children}</Container>
      </div>
      <footer className="footer px-3 px-md-0">
        <Container>
          <div className="footer__content px-3 px-md-0">
            <Image src={clearingHubImage} className="footer__content__logo" />
          </div>
        </Container>
      </footer>
    </Fragment>
  );
}
