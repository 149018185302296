import React, { useState, useGlobal, setGlobal, useDispatch } from "reactn";
import { Col, Row, Form, Card } from "react-bootstrap";
import { If, Then, Else } from "react-if";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from 'axios';

import { BASE_URL } from '../../Config';

import { Layout, Back, PersonnelInfo, SideCard } from "Views/Components";
import { Button, Field, Textarea, Bubble } from "Components";
import { namedRoutes } from "Routes";
import { useTitle } from "Utils";

export default function WrongInfo({ history }) {

  useTitle("Wrong Information");

  /**
   * state
   */
  const [screen, setScreen] = useState("form");

  const [user, nn] = useGlobal("user");
  console.log({user});

  const logout = useDispatch("auth.logout");

  return (
    <Layout title="Wrong Details" className="wrong-info-page px-3 px-md-0">
      <If condition={Boolean(screen === "form")}>
        <Then>
          <Row className="justify-content-between">
            <Col lg="7">
              <p className="page-header font-size-16">
                Fill only if you have wrong information on Certificate as
                provided below
              </p>

              <Formik
                initialValues={{ reason: "" }}
                validationSchema={Yup.object({
                  reason: Yup.string().required("Reason is required"),
                })}
                children={(props) => (
                  <WrongInfoForm {...props} history={history} />
                )}
                onSubmit={(params, { setSubmitting, setErrors }) => {
                  console.log({params});
                  params.nssno = user.nssno;
                  const url = `${BASE_URL}/api/wrong-info`;
                  axios.post(url, 
                  params).then( res => {
                    console.log(res);
                    if(res.data.message ){ 
                      setTimeout(()=>{
                        logout();
                      }, 2000);
                      return setScreen("success");
                    }
                  }).catch( e=>{
                    setErrors({reason: "Error occured. Try again."})
                    setSubmitting(false);
                  })
                  
                }}
              />
            </Col>
            <Col lg="4">
              <SideCard header="Summary">
                <PersonnelInfo>
                  <PersonnelInfo.Data header="Name" content={user.fullname} />
                  <PersonnelInfo.Data
                    header="NSS Number"
                    content={user.nssno}
                    contentCss="font-size-18"
                  />
                  <PersonnelInfo.Data
                    header="School"
                    content={user.institution}
                  />
                  <PersonnelInfo.Data header="Company" content={user.place} />
                </PersonnelInfo>
              </SideCard>
            </Col>
          </Row>
        </Then>
        <Else>
          <div className="">
            <Row className="justify-content-center">
              <Col md="8" lg="6">
                <Card className="mb-5">
                  <Card.Body className="px-24 py-5">
                    <Bubble
                      size="58"
                      color="green"
                      icon="check"
                      iconSize="32"
                      className="mb-12 mx-auto"
                    />

                    <p className="text-center font-size-24">Success</p>
                    <p className="grey-text text-center">
                      Your feedback has been submitted <br /> successfully.
                      We’ll contact you soon.
                    </p>
                  </Card.Body>
                </Card>
                <Back
                  text="Back to site"
                  className="back"
                  onClick={() => history.push(namedRoutes.home.index)}
                />
              </Col>
            </Row>
          </div>
        </Else>
      </If>
    </Layout>
  );
}

export function WrongInfoForm({ handleSubmit, isSubmitting, values, history }) {
  const { reason } = values;

  return (
    <Form style={{ maxWidth: 560 }} className="mb-32">
      <Field
        name="reason"
        value={reason}
        as="textarea"
        component={Textarea}
        useComponent={false}
        className="p-16 p-md-24"
        containerProps={{ className: "mb-md-5" }}
        placeholder="Enter reasons in detail why the information on the certificate is incorrect"
        style={{ minHeight: 100 }}
      />

      <Row className="flex-column-reverse flex-md-row">
        <Col md="6">
          <Back onClick={() => history.push(namedRoutes.dashboard.index + "?from=home")} />
        </Col>
        <Col md="6">
          <Button 
            className="btn--success --h-56 w-100 mb-3"
            value="Submit"
            onClick={handleSubmit}
            isSubmitting={isSubmitting}
            type="submit"
          />
        </Col>
      </Row>
    </Form>
  );
}
