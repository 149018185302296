import { rootState } from "./";

/**
 * Login function
 *
 * Obviously this must be changed
 * @param {*} state
 * @param {*} dispatch
 */
export const login = ({user}, dispatch) => {
  localStorage["access_token"] = "helloworld";
  console.log('dtdt-old',user)
  if(!user){
    user = { full_name: "Eldad" }
  }
  console.log('dtdt',user)
  return { user, isAuthenticated: true };
};

export const logout = (state, dispatch) => {
  return { ...rootState, identification: null, deliveryData: null };
};

export const setToken = (accessToken) =>
  (localStorage["access_token"] = accessToken);
