import React from "react";
import { Switch, Route } from "react-router-dom";

import { namedRoutes } from "Routes";
import SelfIdentification from "./Dashboard/SelfIdentification";
import Transaction from "./Dashboard/Transaction";
import Certificate from "./Dashboard/Certificate";
import WrongInfo from "./Dashboard/WrongInfo";
import Delivery from "./Dashboard/Delivery";
import Profile from "./Dashboard/Profile";
import Verify from "./Dashboard/Verify";
import Track from "./Dashboard/Track";

export default function Dashboard() {
  return (
    <Switch>
      <Route
        exact
        path={namedRoutes.dashboard.identification}
        component={SelfIdentification}
      />
      <Route
        exact
        path={namedRoutes.dashboard.wrongInfo}
        component={WrongInfo}
      />
      <Route
        exact
        path={namedRoutes.dashboard.transaction}
        component={Transaction}
      />
      <Route
        exact
        path={namedRoutes.dashboard.certificate}
        component={Certificate}
      />
      <Route exact path={namedRoutes.dashboard.delivery} component={Delivery} />
      <Route exact path={namedRoutes.dashboard.verify} component={Verify} />
      <Route exact path={namedRoutes.dashboard.track} component={Track} />

      <Route exact path={namedRoutes.dashboard.index} component={Profile} />
    </Switch>
  );
}
