import React, { useState, useGlobal, useDispatch } from "reactn";
import { Card, Row, Col, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";

import { NeedHelp, PersonnelInfo } from "Views/Components";
import { namedRoutes } from "Routes";
import { Button } from "Components";
import moment from "moment";
import { useTitle } from "Utils";

export default function Finish({ history, standalone }) {

  useTitle("Completed");
  const [alert, setAlert] = useState(standalone ? false : true);
  const [user] = useGlobal("user");
  console.log({ user });

  const logout = useDispatch("auth.logout");

  if(!user.transcript){
    console.log("Finish(): TRANSCRIPT NOT VALID. Redirecting...");
    history.push(namedRoutes.dashboard.delivery.replace(":step", 4));
  }

  if(!user?.invoice.paid || parseFloat(user.invoice.paid) < 1.0 ){
    //alert('Payment Not Verified Yet');
    console.log("Finish(): INVOICE NOT PAID. Redirecting...");
    history.push(namedRoutes.dashboard.delivery.replace(":step", 4));
  }

  return (
    <div>
      <Card>
        <Card.Body className="p-0">
          <div className="d-none d-md-flex p-36 align-items-center justify-content-between">
            <h1 className="font-size-24 m-0">NSS Certificate Request Status ({user?.invoice && user.invoice.statusid === 2 ? "In NSS Queue" 
                    : user.invoice.statusid === 9 ? "Enroute Delivery" 
                    : user.invoice.statusid === 10 ? "Delivered" 
                    : user.invoice.statusid === 8 ? "Dispatched" 
                    :  user.invoice.statusid === 7 ? "Processing Complete" 
                    : user.invoice.statusid === 4 ? "Processing"
                    : user.invoice.statusid === 3 ? "On Hold" 
                    : user.invoice.statusid === 5 ? "Preordered Certificate"
                    : user.invoice.statusid === 6 ? "Awaiting Service Charge" 
                    : "In NSS Queue"})</h1>
            <NeedHelp />
          </div>
          <span className="underline d-none d-md-block" />

          <div className="py-md-48 px-md-32">
            <Alert
              variant="light"
              className="alert--success mx-4 mx-md-0 mb-0 mt-4 mt-md-0 mb-md-5"
              show={alert}
            >
              <div className="alert__content"> 
                Congratulations, your delivery information has been successfully submitted!
                {
                  user?.cert?.link ?
                  <p> Your would be notified when your Digital Certificate has been sent to ( <strong> {user?.transcript?.email_address || ""} </strong> ), and physical certificate to your address.</p> 
                  :
                  null
                }
              </div>
              <span className="alert__close" onClick={() => setAlert(false)} />
            </Alert>

            <Row className="justify-content-between flex-column-reverse flex-md-row">
              <Col md="5">
                <PersonnelInfo
                  className="px-4 px-md-0 py-4 py-md-0"
                  variant="inverse"
                >
                  <PersonnelInfo.Data
                    header="Request Status"
                    content={user?.invoice && user.invoice.statusid === 2 ? "In NSS Queue" 
                    : user.invoice.statusid === 9 ? "Enroute Delivery" 
                    : user.invoice.statusid === 10 ? "Delivered" 
                    : user.invoice.statusid === 8 ? "Dispatched" 
                    :  user.invoice.statusid === 7 ? "Processing Complete" 
                    : user.invoice.statusid === 4 ? "Processing"
                    : user.invoice.statusid === 3 ? "On Hold" 
                    : user.invoice.statusid === 5 ? "Preordered Certificate"
                    : user.invoice.statusid === 6 ? "Awaiting Service Charge" 
                    : "In NSS Queue"}
                  />
                  <PersonnelInfo.Data
                    header="Date Requested"
                    content={moment(user.transcript.trxadddate).toLocaleString()}
                  />
                  <PersonnelInfo.Data
                    header="Service Request Number"
                    content={user?.transcript.formno}
                  />
                  { false ? <PersonnelInfo.Data
                    header="Payment Status"
                    content="Payment received"
                  />: null }
                  <PersonnelInfo.Data
                    header="Courier AWB"
                    content={user?.invoice?.awb || "Awaiting AWB"}
                  />
                  <PersonnelInfo.Data
                    header="Requested By"
                    content={user?.fullname}
                  />
                  

                  <Link
                      className="green-text"
                      to={namedRoutes.dashboard.transaction}
                      //to={`https://verifydocument.nss.gov.gh/nssno-${user.invoice.guid}`}
                    >
                      VIEW DETAILS OF YOUR REQUEST
                    </Link>
                </PersonnelInfo>
              </Col>
              <Col md="5" lg="4">
                <div className="qrcode py-4 py-md-0">
                { (user?.transcript && user?.transcript?.enrolmentid < 8) || (user?.guid && user?.guid.length > 7 && !user?.transcript?.enrolmentid ) ? 
                <p
                    className="font-size-16 font-size-md-18 mb-0 px-4 px-md-0 line-height-12"
                    style={{ opacity: 0.6 }}
                  >
                    Kindly scan the QR Code Below {" "}
                    
                    { false && user.cert_type === 2 ?
                    <span>
                      Or{" "}
                    <Link
                      className="green-text"
                      to={namedRoutes.dashboard.certificate}
                      //to={`https://verifydocument.nss.gov.gh/nssno-${user.invoice.guid}`}
                    >
                      CLICK HERE
                    </Link>{" "}
                    </span>
                    : null 
                    }
                    to view your certificate
                  </p> : null }
                  { true || (user?.transcript && user?.transcript?.enrolmentid < 8) || (user?.guid && user?.guid.length > 7 && !user?.transcript?.enrolmentid) ?
                  
                  <div>
                  <QRCode
                    //value={namedRoutes.dashboard.certificate}
                    value={`https://verifydocument.nss.gov.gh/nssno-${user?.guid}`}
                    includeMargin={true}
                    size={285}
                    className="mx-auto d-block"
                  />
                  
                  <img alt="logo" style={{position:"absolute", top: 130, left: 160}} width="50" height="50" src="https://nss-assets.s3.eu-west-1.amazonaws.com/national-service-log-transparent-bg.png"/>
                  
                  </div> 
                  
                  : null }
                  <div className="px-4 px-md-0">
                    <Button
                      className="btn--danger --h-56 w-100 mb-4"
                      //onClick={() => history.push(namedRoutes.dashboard.index)}
                      onClick={ () => logout() }
                      value="Close Section"
                    />

                     
                     
                      { user?.invoice?.awb?.length > 2 && user?.cert_type != 2 ?
                      <a target="_blank" className="blue-text d-block" href={`http://www.dhl.com/en/express/tracking.shtml?pageToInclude=RESULTS&type=fasttrack&AWB=${user?.invoice?.awb}`}>
                      Track DHL waybill
                      </a> 
                      :
                      <span></span>
                      }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
