import React, { useGlobal, useEffect, Fragment, useState, setGlobal, useDispatch } from "reactn";
import { Card, Row, Col, Form } from "react-bootstrap";

import {
  NeedHelp,
  PersonnelInfo,
  ConsentMessage,
  Back,
} from "Views/Components";
import { namedRoutes } from "Routes";
import { Button } from "Components";
import axios from 'axios';

import {BASE_URL} from '../../../Config';

export default function Consent({ history, prev, next }) {
  /**
   * states
   */
  const [deliveryData, setData] = useGlobal("deliveryData");
  const [user] = useGlobal("user");
  const [screen, setScreen] = useState("initial");
  const logout = useDispatch("auth.logout");

  /**
   * effect
   */
  useEffect(() => {
    // redirect back to step one if no delivery data
    if (!deliveryData) {
      history.push(namedRoutes.dashboard.delivery.replace(":step", prev));
    }

    if(user?.invoice?.paid && parseFloat(user.invoice.paid) > 1.0 ){
      //alert('Payment Not Verified Yet');
      console.log(parseFloat(user.invoice.paid));
      logout();
      history.push(namedRoutes.home.index);
    }
  }, [deliveryData, history, prev]);

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="d-flex align-items-md-center justify-content-between p-20 p-md-36">
          <h1
            className="mb-0 font-size-16 font-size-md-20 font-weight-400 w-60"
            style={{ letterSpacing: -0.4, width: "68%" }}
          >
            Electronic Consent Authorization
          </h1>
          <NeedHelp show />
        </div>

        <span className="underline" />

        <div className="body p-20 p-md-48">
          {screen === "initial" && (
            <ConsentInfo
              deliveryData={deliveryData}
              next={() => setScreen("consent")}
              back={() =>
                history.push(
                  namedRoutes.dashboard.delivery.replace(":step", prev)
                )
              }
            />
          )}
          {screen === "consent" && (
            <ConsentForm
              next={() =>
                setData({ ...deliveryData, consent: true }) |
                history.push(
                  namedRoutes.dashboard.delivery.replace(":step", next)
                )
              }
              back={() => setScreen("initial")}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export function ConsentForm({ next, back }) {
  const [user] = useGlobal("user");
  const [saving, setSaving] = useState(false);
  return (
    <div className="py-5">
      <div style={{ maxWidth: 424 }} className="mx-auto">
        <Form onSubmit={(e) => {
          e.preventDefault();
          setSaving(true);
          axios.post(`${BASE_URL}/api/certificate-consent`,{
            nssno: user.nssno,
            dob: user.dob,
            signature: 'kjfdghgjvbhvg'
        })
        .then( res => {
          setSaving(false);
          console.log(res);
          const { consent } = res.data;
          setGlobal({ user: {...user, consent } });
          next();
        }).catch(error => {
          console.log({ error })
          alert(error?.response?.data?.message || "Error coccured");
          setSaving(false);
        });
        }}>
          <div className="mb-5">
            <Form.Check
              custom
              type="checkbox"
              required
              value="true"
              id="consent-check"
              label="By checking this box, you confirm that you are the rightful owner of this certificate."
            />
          </div>
          <Row className="flex-column-reverse flex-md-row">
            <Col md="6">
              <Back onClick={() => back()} />
            </Col>
            <Col md="6" className="mb-3 mb-md-0">
              <Button
                value="Authorize & Continue"
                className="btn--success --h-56 w-100"
                type="submit"
                isSubmitting={saving}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export function ConsentInfo({ deliveryData, next, back }) {
  const [user] = useGlobal("user");
  return (
    <PersonnelInfo className="consent-initial">
      <PersonnelInfo.Data
        header="Service Order Number"
        content={user?.transcript?.formno.toUpperCase()}
        contentCss="font-size-24"
      />
      <PersonnelInfo.Data
        header="Consent to Release information from Education Records"
        content={<ConsentMessage />}
        headerCss="default-text"
      />
      <PersonnelInfo.Data
        header="Delivery Address"
        content={
          <Fragment>
            {user.fullname} <br />
            {deliveryData?.personnel.address1}, <br />
            {deliveryData?.personnel.address2}
            {deliveryData?.personnel.address2 && (
              <Fragment>
                ,<br />
              </Fragment>
            )}
            {deliveryData?.personnel.city}, {deliveryData?.personnel.region}, Ghana
          </Fragment>
        }
      />
      <PersonnelInfo.Data
        content={
          <Row className="flex-column-reverse flex-md-row">
            <Col md="6">
              <Back onClick={() => back()} />
            </Col>
            <Col md="6" className="mb-3 mb-md-0">
              <Button
                className="btn--success --h-56 w-100"
                value="Continue"
                onClick={() => {
                  next();
                }}
                //isSubmitting={saving}
              />
            </Col>
          </Row>
        }
      />
    </PersonnelInfo>
  );
}
