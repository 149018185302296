import React, { Fragment, useGlobal, setGlobal } from "reactn";
import { Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from 'axios';

import {
  Layout,
  SideCard,
  PersonnelInfo,
  supportedIds,
} from "Views/Components";
import { Button, Field } from "Components";
import { namedRoutes } from "Routes";
import { BASE_URL } from "Config";
import { useTitle } from "Utils";

export default function SelfIdentification({ history }) {

  useTitle("Identification");
  
  /**
   * state
   */
  const [identification, setId] = useGlobal("identification");
  const [user] = useGlobal("user");



  /*if(user && user.contact.idtypeid && user.contact.idno){
    console.log(user)
    //alert('id already saved')
    setGlobal({identification: {...user.contact, idTypeNumber: user.contact?.idtypeid, nssno: user.nssno }  });
    //setId({ ...identification, form: params }) |
    // route the user to the ID verification page
    history.push(namedRoutes.dashboard.verify)
  }*/ 

  return (
    <Layout title="Self Identification" className="px-3 px-md-0">
      <Row className="justify-content-between">
        <Col lg="7">
          <p className="page-header">Select your means of identification</p>

          <Formik
            initialValues={{
              type: identification?.form?.type || "",
              number: identification?.form?.number || "",
              date_of_birth: identification?.form?.date_of_birth || "",
            }}
            validationSchema={Yup.object({
              type: Yup.string().required(),
              number: Yup.string().required("Number is required"),
              date_of_birth: Yup.string().when("type", (type, schema) =>
                type === "drivers_license"
                  ? schema.required("Date of Birth is requried")
                  : schema
              ),
            })}
            children={(props) => <IDForm {...props} />}
            onSubmit={(params, { setSubmitting, setErrors }) =>{
              /**
               * Make api call to validate the ID
               * If the details is invalid, just use the setErrors 
               * To make the invalid field

               * If valid, you can set the global state identification
               * so that you can know the ID and it details anywhere
               *
               * Personally ID write a component for each ID card so that 
               * I can just call the component without writing too much
               * But you are free to do what you think it right
               */

              // set the global state with the ID details,
              // I'm pretty sure you might append the api data to the global state
              console.log(params);
              const reqParams = {
                idTypeNumber: params.type,
                idNumber: params.number,
                nssno: user.nssno
              }
              if(params.date_of_birth){
                reqParams.dob = params.date_of_birth;
              }
              axios.post(`https://2x190vwi57.execute-api.eu-west-1.amazonaws.com/v1/verify-id`, 
              reqParams).then( res => {
                console.log(res);
                if(res.data && res.data.ResponseCode ){
                  if(res.data.ResponseCode == '200'){
                     
                    setGlobal({identification: {...res.data, idno: params.number, idtypeid: params.type,  idTypeNumber: params.type, nssno: user.nssno }  });
                    //setId({ ...identification, form: params }) |
                    // route the user to the ID verification page
                    history.push(namedRoutes.dashboard.verify)
                  }
                  if(res.data.ResponseCode == '201'){ 
                    setSubmitting(false)
                    setErrors({number: res.data.Message})
                  }
                  
                }
              }).catch( e=>{
                setErrors({number: "Error occured. Try again."})
                setSubmitting(false);
              })
              
            }}
          />
        </Col>
        <Col lg="4">
          <SideCard header="Summary">
            <PersonnelInfo>
              <PersonnelInfo.Data header="Name" content={user.fullname} />
              <PersonnelInfo.Data
                header="NSS Number"
                content={user.nssno}
                contentCss="font-size-18"
              />
              <PersonnelInfo.Data
                header="School"
                content={user.institution}
              />
              <PersonnelInfo.Data header="Company" content={user.place} />
              <PersonnelInfo.Data
                content={
                  <Button
                    className="btn--danger-outlined --h-56"
                    value="These are not the right details"
                    onClick={() =>
                      history.push(namedRoutes.dashboard.wrongInfo)
                    }
                  />
                }
              />
            </PersonnelInfo>
          </SideCard>
        </Col>
      </Row>
    </Layout>
  );
}

export function IDForm({ handleSubmit, isSubmitting, values, setFieldValue }) {
  const { type } = values;
  const idCard = supportedIds.find((id) => type === id.value);

  return (
    <Fragment>
      <div
        className="d-flex mb-4 mb-lg-5 pb-lg-5 mx-n2"
        style={{ flexWrap: "wrap" }}
      >
        {supportedIds.map((id, key) => (
          <div className="px-2 mb-3" key={key}>
            <div className={`light-card ${type === id.value && "active"}`}>
              <Form.Check
                defaultChecked={id.value === type}
                name="id"
                type="radio"
                label={<span>{id.label}</span>}
                custom
                id={`id-${id.value}`}
                onChange={() => setFieldValue("type", id.value)}
              />
            </div>
          </div>
        ))}
      </div>

      <Row className="mb-lg-5">
        {idCard?.fields?.map((field, key) => (
          <Col md="10" key={key}>
            <Field
              name={field?.value}
              value={values[field?.value]}
              label={{ value: field?.label }}
              placeholder={field?.placeholder}
            />
          </Col>
        ))}
      </Row>

      <Row className="mb-4 mb-lg-0">
        <Col md="6">
          <Button
            value="Proceed"
            className="w-100 btn--success --h-56"
            isSubmitting={isSubmitting}
            onClick={handleSubmit}
            {...(!type && { disabled: true })}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
