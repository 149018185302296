export default function useCleanPhoneNumber(number, append = false) {
  if (!number) return "";

  if (append) {
    if (number.startsWith("0")) {
      return "+233" + number.substring(1);
    }

    if (number.startsWith("233")) {
      return "+233" + number.substring(number.length, 3);
    }

    return "+233" + number;
  } else {
    if (number.length === 12 || number.length === 13) {
      if (number.startsWith("+233")) {
        return number.substring(number.length, 4);
      }

      if (number.startsWith("233")) {
        return number.substring(number.length, 3);
      }
    } else {
      return "";
    }
  }
}
