import React, { useDispatch, setGlobal, useEffect, useState } from "reactn";
import { Image, Form } from "react-bootstrap";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import axios from 'axios';

import { Button, Field } from "Components";
import { namedRoutes } from "Routes";
import { useTitle } from "Utils";

import logoImage from "Assets/img/site/logo.png";
import { BASE_URL } from "Config";
//import { url } from "inspector";

export default function Home({ history }) {
  /**
   * set title
   */
  useTitle("Home");

  /**
   * dispatcher
   */
  const login = useDispatch("auth.login");

  const urlParams = new URLSearchParams(window.location.search);
  

  const [searchTerm, setSearchTerm] = useState(urlParams.get('nssno') || urlParams.get('q') || "");
  console.log({ searchTerm });

  const [isAutoLogin, setAutoLogin] = useState(urlParams.get('login') === "true" || urlParams.get('q'));
  console.log({ isAutoLogin });

  const loginUser = ({ nssno }) => {

    /*axios.post(`${BASE_URL}/api/verify-nss-number`, {
      nssno: params.search
    })
      .then( res => {
        console.log(res.data);

        if(res.data.responseType && res.data.responseType === "PAYMENT_MADE"){
          let userData = { ...res.data.certificate, invoice: {...res.data.invoice} };

          setGlobal({user: userData});
          return login(userData) 
          //| history.push(namedRoutes.dashboard.index + "?from=home")
          | history.push(namedRoutes.dashboard.track);
        }

        let userData = { ...res.data.certificate };
        if(res.data.contact){
          userData.contact = res.data.contact
        }
        if(res.data.transcript){
          userData.transcript = res.data.transcript
        }
        console.log({ userData })
         
        const user = userData;
        console.log({userss: user})
        setGlobal({user})
        return login(user) 
        | history.push(namedRoutes.dashboard.index + "?from=home")
      })
      .catch( e => {
        console.error(e); 
        //setSubmitting(false)
        if(e.response?.data?.message){
          //setErrors({search: e.response.data.message});
          
          //setErrors({search: e.response.data.message}) 
          //setErrors({search: e.response.data.message}) 
        }else{
          //setErrors({search: 'Error. Please Try again'}) 
        }
      })*/

  }

  useEffect(() => {
    // ...

    //const urlParams = new URLSearchParams(window.location.search);
    //const login = urlParams.get('login');
    //const q = urlParams.get('q');
    if (isAutoLogin) {
      //console.log({ q });
      //const nssno = urlParams.get('nssno') || q; 
      //console.log({ nssno });


      //loginUser({ nssno });
      setSearchTerm(searchTerm);

      console.log('Is Callback');

      document.getElementById("submitButton").click();

    } 
    /*if (login === 'true' ||  q?.length > 5 ) {
      console.log({ q });
      const nssno = urlParams.get('nssno') || q; 
      console.log({ nssno });


      //loginUser({ nssno });
      setSearchTerm(nssno);

      console.log('Is Callback');

      document.getElementById("submitButton").click();

    } */


  }, []);



  return (
    <div className="home-page home-page--home">
      <div className="home-page__image d-none d-lg-block" style={{ background: `url("/img/banner.JPG")`, backgroundSize:"cover"}} />
      <div className="home-page__info">
        <div className="home-page__info__content">
          <div className="heading">
            <Image src={logoImage} />
            <h1 className="heading__header">
              Get your National Service Certificate Delivered to your doorstep.
            </h1>
            <h2 className="heading__sub-header">
              from all regions in the country
            </h2>
          </div>

          <div className="body">
            <Formik
              initialValues={{ search: searchTerm }}
              validationSchema={Yup.object({
                search: Yup.string().required("Please enter your NSS Number"),
              })}
              children={(props) => <SearchNssNumber {...props} />}
              onSubmit={(params, { setSubmitting, setErrors }) =>{
                /**
                 * API service to validate NSS Number
                 * Use setErrors to error if incorrect nss number
                 * setErrors({search: "Invalid NSS Number"})
                 *
                 * after validate NS number, send user to self id if no request
                 * else send user to tracking page
                 */
                axios.post(`${BASE_URL}/api/verify-nss-number`, {
                  nssno: params.search,
                  dob: params.dob
                })
                  .then( res => {
                    console.log(res.data);

                    if(res.data.responseType && res.data.responseType === "PAYMENT_MADE"){
                      let userData = { ...res.data.certificate, invoice: {...res.data.invoice}, dob: params.dob };
 
                      setGlobal({user: userData});
                      return login(userData) 
                      //| history.push(namedRoutes.dashboard.index + "?from=home")
                      | history.push(namedRoutes.dashboard.track);
                    }

                    let userData = { ...res.data.certificate };
                    if(res.data.contact){
                      userData.contact = res.data.contact
                    }
                    if(res.data.transcript){
                      userData.transcript = res.data.transcript
                    }
                    console.log({ userData });
                    
                     
                    const user = userData;
                    user.dob = params.dob;
                    console.log({userss: user})
                    setGlobal({user})

                    if(isAutoLogin){
                      return login(user) 
                      |  history.push(namedRoutes.dashboard.delivery.replace(":step", 1));

                    }
                    return login(user) 
                    | history.push(namedRoutes.dashboard.index + "?from=home")
                  })
                  .catch( e => {
                    console.error(e); 
                    setSubmitting(false)
                    if(e.response?.data?.message){
                      setErrors({search: e.response.data.message});
                      if(e.response?.data?.errorType == "CHARGE_OWED"){
                        const url = e.response?.data?.chargeData.checkoutUrl;
                        window.location.href = url;
                      }
                      //setErrors({search: e.response.data.message}) 
                      //setErrors({search: e.response.data.message}) 
                    }else{
                      setErrors({search: 'Error. Please Try again'}) 
                    }
                  })
                
              }}
            />

            <Link to={namedRoutes.home.search} className="green-text">
              Don't know your NSS Number?
            </Link> 
          </div>
          <div className="footer">
            <Link className="blue-text" to={namedRoutes.home.requirements}>
              See a list of full Requirements
            </Link>
            <Link className="blue-text" to={namedRoutes.home.support}>
              Get Help
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SearchNssNumber({ handleSubmit, isSubmitting, values }) {
  return (
    <Form className="search-form mb-20" id="form">
      <p className="font-size-16 mb-3">Check Certificate Status</p>
      <Form.Group className="mb-0">
        <Field
          containerProps={{ className: "mb-3 w-100" }}
          type="text"
          value={values.search}
          name="search"
          placeholder="Enter your NSS Number"
          className="search-form__input"
        />
        <p className="font-size-16 mb-3">Enter your Date of Birth</p>
        <Field
          containerProps={{ className: "mb-3 w-100" }}
          type="date"
          value={values.dob}
          name="dob"
          placeholder="Enter your Date of Birth"
          className="search-form__input"
        />
        <Button
          value="Check"
          id="submitButton"
          type="submit"
          className="btn--success search-form__button w-100"
          onClick={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </Form.Group>

    </Form>
  );
}
