import React, { useGlobal, useEffect, useState } from "reactn";
import { Row, Col, Card, Image } from "react-bootstrap";

import { NeedHelp, Layout, IDCard } from "Views/Components";
import { namedRoutes } from "Routes";
import { Button } from "Components";
import axios from 'axios';
import { BASE_URL } from "Config";
import { useTitle } from "Utils";

const saveUserId = (history, identification, setSaving) => {

  // make api call to save id
  setSaving(true)
  console.log('make api call to save id');

  if(!identification.idtypeid || identification.idno.length < 1){
    alert("Please provide fresh identification details");
    history.push(namedRoutes.dashboard.identification);
    return;
  }
  
  setSaving(false)
  history.push(
    namedRoutes.dashboard.delivery.replace(":step", 1)
  )
  return;

  /*axios.post(`${BASE_URL}/api/save-user-id`, {
    ...identification
  }).then(res => {
    setSaving(false)
    history.push(
      namedRoutes.dashboard.delivery.replace(":step", 1)
    )
    console.log(res.data)
  })*/



}

export default function Verify({ history }) {

  useTitle("Verification");
  /**
   * state
   */
  const [identification] = useGlobal("identification");
  const [user] = useGlobal("user");
  console.log({ user, identification });

  const [saving, setSaving] = useState(false);

  /**
   * effect
   */
  //useEffect(() => {
  /**
   * I'm pretty sure if the validation of the
   * ID is done, this might change
   *
   * This is to redirect the user back to the Self ID
   * form if it's not filled
   */
  //if (!identification?.form) {
  //history.push(namedRoutes.home.identification);
  //}
  //}, [identification, history]);*/

  return (
    <Layout title="Set Identification" className="verify-page px-3 px-md-0">
      <Row className="justify-content-between">
        <Col sm="12" md="5" className="mb-5 mb-md-0">
          <div className="id-card">
            <div className="id-card__image mb-5">

              {identification.idTypeNumber ?
                <IDCard identification={identification} user={user}/>
                 :
                  <Image
                    width={111}
                    height={151}
                    //src={`/img/id-${identication?.form?.type}.png`} 
                    src={`data:image/png;base64, ${identification.Picture}`}
                    fluid />
              }
            </div>

            <Row>
              <Col md="6" className="mb-4 mb-md-0">
                <Button
                  value="Confirm & Continue"
                  className="btn--success --h-56 w-100"
                  onClick={() => saveUserId(history, identification, setSaving)}
                  //loading={true}
                  loadingColor={'white'}
                  isSubmitting={saving}
                />
              </Col>
              <Col md="6">
                <Button
                  value="This is not me"
                  className="btn--danger-outlined --h-56 w-100"
                  onClick={() =>
                    history.push(namedRoutes.dashboard.identification)
                  }
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12" md="5">
          <Card>
            <Card.Body className="p-0">
              <div className="d-flex align-items-center justify-content-between py-16 px-24 px-md-32">
                <p className="header fontSize-18 fontWeight-700 mb-0">
                  Summary
                </p>
                <NeedHelp show />
              </div>
              <span className="underline" />
              <div className="py-3 px-24 px-md-32 personnel-info">
                <div className="data">
                  <p className="data__header">Name</p>
                  <p className="data__content">{user.fullname}</p>
                </div>
                <div className="data">
                  <p className="data__header">NSS Number</p>
                  <p className="data__content fontSize-24">{user.nssno}</p>
                </div>

                <div className="data">
                  <p className="data__header">School</p>
                  <p className="data__content">
                    {user.institution}
                  </p>
                </div>

                <div className="data">
                  <p className="data__header">Company</p>
                  <p className="data__content">{user.place}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
