import React from "react";
import { Link } from "react-router-dom";
import { namedRoutes } from "Routes";
import { Image } from "react-bootstrap";

import closeIcon from "Assets/img/icons/close.svg";

export default function Close() {
  return (
    <Link className="close-button" to={namedRoutes.home.index}>
      <span>close</span>
      <Image src={closeIcon} />
    </Link>
  );
}
