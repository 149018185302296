import React, { Fragment, useGlobal, useState } from "reactn";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { If, Then, Else } from "react-if";

import { Bubble, Button } from "Components";
import { useTitle } from "Utils";
import { Layout } from "Views/Components";

import certificateImage from "Assets/img/photos/certificate.png";
import downloadIcon from "Assets/img/icons/download.svg";
import instagramIcon from "Assets/img/icons/instagram.svg";
import twitterIcon from "Assets/img/icons/twitter.svg";
import facebookIcon from "Assets/img/icons/facebook.svg";

export default function Certificate() {
  useTitle("Certificate");

  const [user] = useGlobal("user");
  console.log({ user });

  const [link, setLink] = useState(user.cert.link ? user.cert.link : null);
 

  return (
    <If condition={Boolean(window.innerWidth >= 991)}>
      <Then>
        <div className="certificate-page">
          <CertificateData link={link} user={user} />
        </div>
      </Then>
      <Else>
        <Layout title="" className="certificate-page">
          <CertificateData link={link} user={user} />
        </Layout>
      </Else>
    </If>
  );
}

export function CertificateData({ link, user }) {
  return (
    <Container className="h-100 overflow-auto">
      <Row className="align-items-center h-100">
        <Col xs="12" className="my-auto py-lg-5">
          <Row>
            <Col lg="6" className="mb-2 mb-md-3 md-lg-0">
              { false ?  
                  <iframe src={link.replace("encrypted","specimen")} width="100%" height="500px">
                  </iframe>
              :
              <Image src={certificateImage} fluid className="mx-auto d-block" />
              }
            </Col>
            <Col lg="6">
              <div className="mb-32">
                <p className="mb-10">Certificate Recipient</p>
                <Card>
                  <Card.Body className="d-flex align-items-center">
                    <Bubble
                      size="64"
                      color="grey"
                      content="EN"
                      className="mr-4"
                    />
                    <p className="mb-0">{ user.fullname }</p>
                  </Card.Body>
                </Card>
              </div>

              <div className="d-flex align-items-center mb-5">
                <Button
                  className="btn--success --h-56 px-3 mr-30"
                  onClick={()=>window.open(link)}
                  value={
                    <Fragment>
                      <span className="d-block mr-3">Download</span>
                      <Image src={downloadIcon} />
                    </Fragment>
                  }
                > 
                </Button>

                <div className="d-flex align-items-center socials">
                  {[
                    { link: "https://facebook.com", icon: facebookIcon },
                    { link: "https://twitter.com", icon: twitterIcon },
                    { link: "https://instagram.com", icon: instagramIcon },
                  ].map((socialMedia, key) => (
                    <a
                      key={key}
                      href={socialMedia.link}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Image src={socialMedia.icon} />
                    </a>
                  ))}
                </div>
              </div>
              <p className="mb-0 line-height-13">
                Hi {user.fullname}, thanks for choosing NSS certificate. Your order has
                been placed successfully and your certificate will be delivered
                to you soon. In the mean time, download this digital copy or
                check your email for a copy.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
