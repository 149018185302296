import React, { Children, cloneElement, Fragment } from "react";

export function Div(props) {
  return false;
}

export function Step({
  title,
  active,
  completed,
  index,
  isLast,
  component,
  icon,
  iconActive,
  iconCompleted,
  ...props
}) {
  const modifiers = () => {
    let mods = "";
    mods += active ? " --active" : "";
    mods += completed ? " --completed" : "";
    mods += icon ? " --icon" : "";

    return mods;
  };

  const showIcon = () => {
    if (icon) {
      if (completed) {
        if (iconCompleted) {
          return iconCompleted;
        }
      }

      if (active) {
        if (iconActive) {
          return iconActive;
        }
      }

      return icon;
    }

    if (completed) {
      return <i className="material-icons">check</i>;
    } else {
      return index + 1;
    }
  };

  return (
    <div
      className={`steps__step ${modifiers()}`}
      style={{ width: `${props.width}%` }}
    >
      <div className="steps__step__icon">
        <span>{showIcon()}</span>
      </div>
      <div className="steps__step__title">{title}</div>
      {!isLast && <div className="steps__step__tail" />}
    </div>
  );
}

export function Steps({ children, current, className, ...props }) {
  const filteredChildren = React.Children.toArray(children).filter((c) => !!c);

  let Render = null;
  let childProps = {};

  const prev = current - 1 ? current - 1 : 1;
  const next = parseInt(current) + 1;

  current = current ? current - 1 : 0;

  return (
    <Fragment>
      <div className="steps-container w-100">
        <div className={`steps ${className}`} {...props}>
          {Children.map(filteredChildren, (child, index) => {
            if (!child) {
              return null;
            }

            childProps = {
              ...child.props,
              index: index,
              step: 0,
              width: (100 / filteredChildren.length).toFixed(2),
            };

            if (index === current) {
              childProps.active = true;
              Render = childProps.component
                ? childProps.component
                : childProps.children
                ? childProps.children
                : null;
            }

            if (current > index) {
              childProps.completed = true;
            }

            if (filteredChildren.length - 1 === index) {
              childProps.isLast = true;
            }

            return cloneElement(child, { ...childProps });
          })}
        </div>
      </div>
      {Render ? (
        <div className="py-4 my-3 py-md-5 my-md-5 px-3 px-md-0">
          <Render {...childProps} prev={prev} next={next} />
        </div>
      ) : (
        <div className="pt-4 mt-3 pt-md-5 pt-md-5 px-3 px-md-0" />
      )}
    </Fragment>
  );
}

Steps.Step = Step;

export default Steps;
