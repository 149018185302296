import React from "react";
import { Alert, Image } from "react-bootstrap";

import dangerIcon from "Assets/img/icons/danger.svg";

export default function ConsentMessage() {
  return (
    <Alert variant="light" className="alert--danger" style={{ maxWidth: 489 }}>
      <Image src={dangerIcon} className="alert__icon" />
      <p className="alert__content">
      Only the NSS personnel, by themself, may sign this form  Any attempt to impersonate the owner of the records is criminal and may lead to prosecution
      </p>
    </Alert>
  );
}
