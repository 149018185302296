import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Layout, Steps } from "Views/Components";
import { namedRoutes } from "Routes";

import Overview from "./Delivery/Overview";
import Consent from "./Delivery/Consent";
import Payment from "./Delivery/Payment";
import Finish from "./Delivery/Finish";
import Info from "./Delivery/Info";
import GhanaGPS from "./Delivery/GhanaGPS";

export default function Delivery({ history }) {
  /**
   * variables
   */
  const { step } = useParams();

  /**
   * effect
   */
  useEffect(() => {
    if (!(step && step > 0 && step <= 6)) {
      history.push(namedRoutes.dashboard.delivery.replace(":step", 1));
    }
  });

  return (
    <Layout title="Delivery">
      <Steps current={step} style={{ minWidth: 624 }} className="px-3">
      <Steps.Step
          title="Address Info"
          component={GhanaGPS}
          history={history}
        />
        <Steps.Step
          title="Service & Delivery Info"
          component={Info}
          history={history}
        />
        <Steps.Step
          title="Give Consent"
          component={Consent}
          history={history}
        />
        <Steps.Step title="Overview" component={Overview} history={history} />
        <Steps.Step
          title="Payment Method"
          component={Payment}
          history={history}
        />
        <Steps.Step title="Finish" component={Finish} history={history} />
      </Steps>
    </Layout>
  );
}
