const supportedIds = [
  {
    value: 1,
    label: "Voter's ID",
    fields: [
      {
        value: "number",
        label: "Enter your Voter's Identification Number",
        placeholder: "",
      },
    ],
  },
  {
    value: 2,
    label: "Passport",
    fields: [
      {
        value: "number",
        label: "Enter your Passport Identification Number",
        placeholder: "",
      },
    ],
  },
  {
    value: 3,
    label: "Drivers’ License",
    fields: [
      {
        value: "number",
        label: "Enter your Certificate Of Competence Number",
        placeholder: "",
      },
      {
        value: "date_of_birth",
        label: "Enter your Date of Birth as it appear on Driver's License",
        placeholder:
          "Enter your Date of Birth as it appear on Driver's License e.g 1987-07-26",
      },
    ],
  },
  {
    value: 7,
    label: "New Drivers’ License",
    fields: [
      {
        value: "number",
        label: "Enter your Certificate Of Competence Number",
        placeholder: "",
      },
    ],
  },
  {
    value: 4,
    label: "SSNIT",
    fields: [{ value: "number", label: "Enter your FSS number" }],
  },
];

export default supportedIds;
