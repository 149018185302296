import React, { useGlobal, useDispatch, Fragment, setGlobal } from "reactn";
import { Row, Col } from "react-bootstrap";

import { NeedHelp, Layout } from "Views/Components";
import Table, { Th, Td } from "Components/Table";
import { namedRoutes } from "Routes";
import { Button } from "Components";
import { BASE_URL } from "Config";
import axios from "axios";
import { useState } from "react";

export default function Results(props) {
console.log(props)
  const { history } = props;
  /**
   * state
   */
  const [results] = useGlobal("results");
  console.log('r',results)

  const [loading, setLoading] = useState(false);
  console.log({ loading });

  /**
   * dispatcher
   */
  const login = useDispatch("auth.login");

  /**
   * @param {*} user
   *
   * when user is selected, set authenicated as true
   * and set user global to selected user payload
   */
  const selfId = (user) =>{
    
    if(loading){
      return;
    }
    setLoading(true);

    axios.post(`${BASE_URL}/api/verify-nss-number`, {
      nssno: user.nssno
    })
      .then( res => {       
        setLoading(false);
        console.log(res.data);

        if(res.data.responseType && res.data.responseType === "PAYMENT_MADE"){
          let userData = { ...res.data.certificate, invoice: {...res.data.invoice} };

          setGlobal({user: userData});
          return login(userData) 
          //| history.push(namedRoutes.dashboard.index + "?from=home")
          | history.push(namedRoutes.dashboard.track);
        }

        let userData = { ...res.data.certificate };
        if(res.data.contact){
          userData.contact = res.data.contact
        }
        if(res.data.transcript){
          userData.transcript = res.data.transcript
        }
        console.log({ userData })
         
        const user = userData;
        console.log({userss: user});
        
        setGlobal({user})
        return login(user) 
        | history.push(namedRoutes.dashboard.index + "?from=home")
      })
      .catch( e => {
        console.log(e); 
        setLoading(false);
        if(e.response?.data?.message && e.response.status < 500){
          alert(e.response.data.message);
          //setErrors({search: e.response.data.message});
          
          //setErrors({search: e.response.data.message}) 
          //setErrors({search: e.response.data.message}) 
        }else{
          //setErrors({search: 'Error. Please Try again'}) 
        }
      })

    //setGlobal({user});
    //return login() | history.push(namedRoutes.dashboard.index + "?from=results");
  }
  //const selfId = (user) =>
  //  login() | history.push(namedRoutes.dashboard.index + "?from=results");

  return (
    <Layout title="Results" className="results-page">
      {/*
       * I think it would be better to write the table responsive view as a component
       * Having to do the implementation twice is against DRY rules
       * It would make sense to update this side
       */}
      
      <div className="d-none d-md-block">
        <DesktopView results={results} selfId={selfId} />
      </div>
      <div className="d-block d-md-none">
        <MobileView results={results} selfId={selfId} />
      </div>
      <div style={{textAlign:"center", margin: 15, width: "100%"}}>{ loading ? "Loading your information..." : null } </div>

      <div className="mt-5">
        <Button
          className="btn--stateless --h-56 w-100"
          onClick={() => history.push(namedRoutes.home.search)}
          value={
            <Fragment>
              <i className="material-icons">keyboard_arrow_left</i>
              <span className="d-block ml-2">Back</span>
            </Fragment>
          }
        />
      </div>
    </Layout>
  );
}

export function DesktopView({ results, selfId }) {
  return (
    <Table
      header="Click on your NSS number to proceed"
      headingSide={<NeedHelp />}
    >
      <thead>
        <tr>
          <Th>NSS Number</Th>
          <Th>Full Name</Th>
          <Th>Institution</Th>
          <Th>Qualification</Th>
          <Th>Place of Service</Th>
        </tr>
      </thead>
      <tbody>
        {results.map(result => {
          return (
            <tr key={result.nssno} onClick={()=>selfId(result)}>
              <Td>{result.nssno}</Td>
              <Td>{result.fullname}</Td>
              <Td>{result.institution}</Td>
              <Td>{result.qualification}</Td>
              <Td>{result.place}</Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export function MobileView({ results, selfId }) {
  return (
    <Row>
      <Col xs="12">
        <h1 className="mb-4 font-size-18 px-3">
          Click on your NSS number to proceed
        </h1>
      </Col>
      <Col className="px-0">
        <Table className="table--compact">
          <tbody>
            {results.map((result, key) => {
              return (
                <tr key={result.nssno} onClick={()=>selfId(result)}>
                  <Td>
                    <div className="table__data">
                      <div className="table__data__header">NSS Number</div>
                      <div className="table__data__data">{result.nssno}</div>
                    </div>
                    <div className="table__data">
                      <div className="table__data__header">Full Name</div>
                      <div className="table__data__data">{result.fullname}</div>
                    </div>
                    <div className="table__data">
                      <div className="table__data__header">Institution</div>
                      <div className="table__data__data">
                      {result.institution}
                      </div>
                    </div>
                    <div className="table__data">
                      <div className="table__data__header">Qualification</div>
                      <div className="table__data__data">
                      {result.qualification}
                      </div>
                    </div>
                    <div className="table__data">
                      <div className="table__data__header">
                        Place of Service
                      </div>
                      <div className="table__data__data">
                      {result.place}
                      </div>
                    </div>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}
