import React, { useGlobal } from "reactn";
import { Image } from "react-bootstrap";

import { Layout, Steps } from "Views/Components";
import Finish from "./Delivery/Finish";
import { useTitle } from "Utils";


const getNumber = ( user ) => {

  if(user.invoice.statusid === 2){
    return "1";
  }

  if(user.invoice.statusid === 4){
    return "2";
  }

  if(user.invoice.statusid === 7){
    return "3";
  }

  if(user.invoice.statusid === 8){
    return "4";
  }

  if(user.invoice.statusid === 9){
    return "5";
  }

  if(user.invoice.statusid === 10){
    return "6";
  }
  //alert(user.invoice.statusid)

  return "1";
}
export default function Track({ history }) {

  useTitle("Tracking");
  
  const [user] = useGlobal("user");
  console.log({user});

  return (
    <Layout title="Track Delivery">
      <Steps current={getNumber(user)} style={{ minWidth: 624 }}>
        <Steps.Step
          title="In queue"
          icon={<Image src="/img/in-queue.svg" />}
          iconActive={<Image src="/img/in-queue-completed.svg" />}
          iconCompleted={<Image src="/img/in-queue-completed.svg" />}
        />
        <Steps.Step
          title="Processing"
          icon={<Image src="/img/processing.svg" />}
          iconActive={<Image src="/img/processing-completed.svg" />}
          iconCompleted={<Image src="/img/processing-completed.svg" />}
        />
        <Steps.Step
          title="Processing Complete"
          icon={<Image src="/img/processing-complete.svg" />}
          //iconActive={<Image src="/img/processing-complete-completed.svg" />}
          //iconCompleted={<Image src="/img/processing-complete-completed.svg" />}
          iconActive={<Image src="/img/processing-complete-final.svg" />}
          iconCompleted={<Image src="/img/processing-complete-final.svg" />}
        />
        <Steps.Step
          title="Dispatched"
          icon={<Image src="/img/dispatched.svg" />}
          iconActive={<Image src="/img/dispatched-completed.svg" />}
          iconCompleted={<Image src="/img/dispatched-completed.svg" />}
        />
        <Steps.Step
          title="Shipped"
          icon={<Image src="/img/shipping.svg" />}
          iconActive={<Image src="/img/shipping-completed.svg" />}
          iconCompleted={<Image src="/img/shipping-completed.svg" />}
        />
        <Steps.Step
          title="Delivered"
          icon={<Image src="/img/delivered.svg" />}
          iconActive={<Image src="/img/delivered-completed.svg" />}
          iconCompleted={<Image src="/img/delivered-completed.svg" />}
        />
      </Steps>
      <Finish history={history} standalone />
    </Layout>
  );
}
