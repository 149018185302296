import React, { useState, Fragment } from "react";
import { Modal } from "react-bootstrap";

export default function NeedHelp({ show }) {
  const [modal, setModal] = useState(false);

  return (
    <Fragment>
      <span
        className={`d-${
          show ? "flex" : "none"
        } d-md-flex align-items-center blue-text text-decoration-none pointer`}
        style={{ height: 24 }}
        onClick={() => setModal(true)}
      >
        <span
          className="font-size-14 font-size-md-16 d-block mr-1"
          style={{ whiteSpace: "nowrap" }}
        >
          Need Help
        </span>
        <i className="material-icons">help</i>
      </span>

      <Modal show={modal} onHide={() => setModal(false)} centered size="xl">
        <Modal.Body className="px-3 px-md-5 py-8 py-md-32">
          <div className="d-flex align-items-center justify-content-between px-2 px-md-3 py-24">
            <h1 className="font-size-24 font-size-md-48 mb-0">Help Info</h1>

            <span
              className="pointer red-text font-size-12 d-md-none"
              onClick={() => setModal(false)}
            >
              Close
            </span>
          </div>
          <span className="underline" />

          <div className="px-2 px-md-3 py-24 line-height-15">
            Having a challenge submitting your order? Contact us on whatsapp for
            issues with your request or system on ‭
            <a href="tel: 0544486683" className="default-text">
              (054 448 6683)
            </a>
            ‬, talk to Greater Accra Certificate Room on{" "}
            <a href="tel: 0299011417" className="default-text">
              (0299011417)
            </a>
            , talk to the Ashanti Certificate Room on (0299011413), talk to
            Upper West Certificate Room on{" "}
            <a href="tel: 0299011414" className="default-text">
              (0299011414)
            </a>
            , talk to the Brong Ahafo Certificate Room on{" "}
            <a href="tel: 0299011420" className="default-text">
              (0299011420)
            </a>{" "}
            or our live chat bottom right of your screen. Note: If the details
            provided below does not match your details. Click on "Wrong Details,
            Request Correction" and submit your reasons in the page displayed
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
