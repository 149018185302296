import React, { useEffect, useGlobal, Fragment, setGlobal, useState, useDispatch } from "reactn";
import { Row, Col, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ConsentMessage, Back, PersonnelInfo } from "Views/Components";
import { Button, countryPhoneCode } from "Components";
import { namedRoutes } from "Routes";
import {  IDCard } from "Views/Components";
import axios from 'axios';

import idCardPhoto from "Assets/img/photos/id-card.png";
import countryList from "react-select-country-list";

import { BASE_URL } from '../../../Config';



export default function Overview({ history, prev, next, standalone }) {
  /**
   * state
   */
  const [deliveryData, setData] = useGlobal("deliveryData");
  const [user, setUser] = useGlobal("user");
  console.log({ user });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const logout = useDispatch("auth.logout");
  //console.log(parseFloat(user.invoice.paid));


  /**
   * effect
   */
  useEffect(() => {
    if (!deliveryData || !deliveryData?.consent) {
      history.push(namedRoutes.dashboard.delivery.replace(":step", prev));
    }
    console.log("hiiiiiiii")

    if(user?.invoice?.paid && parseFloat(user.invoice.paid) > 1.0 ){
      //alert('Payment Not Verified Yet');
      console.log(parseFloat(user.invoice.paid));
      logout();
      history.push(namedRoutes.home.index);
    }
  }, []);

  return (
    <Row className="justify-content-between">
      <Col md="6" className="mb-5 pb-3 pb-md-0 mb-md-0">
        <EducationalInformation />

        <div className="d-none d-md-block mt-5 pt-3">
          <ConsentAuthorization />
        </div>
      </Col>
      <Col md="6" lg="5">
        <DeliveryDetails deliveryData={deliveryData} />

        <div className="d-block d-md-none mt-5 pt-3">
          <ConsentAuthorization />
        </div>
      </Col>

      {!standalone && (
        <Col xs="12" className="mt-5 pt-md-5">
          <p className="font-weight-700 text-center mb-4">
            Authorized for order Number {user?.transcript?.formno.toUpperCase()}
          </p>

          <Form
            onSubmit={async(e) => {
              e.preventDefault();
              setIsSubmitting(true);
              

              /*const url = `${BASE_URL}/api/invoice/generate`;

              const response = await axios.post(url,{
                formno: user.transcript.formno
              });
              console.log({response});*/
              
              if(user.cert_type === 2){

                const url = `${BASE_URL}/api/generate-certificate`;

                try{
                  const response = await axios.post(url,{
                    nssno: user.nssno,
                    dob: user.dob
                  }); 
  
                  setIsSubmitting(false);
  
                  const { success, link, invoice, certificate } = response.data;
                  console.log({ success, link, invoice, certificate });
  
                  if(success){
                    setGlobal({ user: {...user, ...certificate, invoice, cert: { link } } });
                    return history.push(namedRoutes.dashboard.delivery.replace(":step", 6)); 
                  }

                }catch(e){
                  setIsSubmitting(false);
                  console.log(e);
                }
                

                //alert("Your Certificate has been emailed to you");
                //history.push(namedRoutes.dashboard.delivery.replace(":step", 5)); 
                //logout();
                return;
              }
              
              //return setUser({ ...user, invoice: response.data.invoice }) |
              return setUser({ ...user }) |
              history.push(
                namedRoutes.dashboard.delivery.replace(":step", next)
              )
            }}
          >
            <Form.Check
              custom
              type="checkbox"
              className="text-center"
              required
              value="true"
              id="consent-check"
              label={
                <span className="d-block mt-md-1 default-text">
                  I have read and accepted the{" "}
                  <Link
                    //to={namedRoutes.home.legals.terms_and_privacy}
                    className="red-text"
                  >
                    Terms Of Use & Privacy Policy
                  </Link>{" "}
                  of Ghana National Service Scheme
                </span>
              }
            />
            <div className="mt-5 pt-md-3 mx-auto" style={{ maxWidth: 480 }}>
              <Row className="flex-column-reverse flex-md-row">
                <Col md="6">
                  <Back
                    onClick={() =>
                      history.push(
                        namedRoutes.dashboard.delivery.replace(":step", prev)
                      )
                    }
                  />
                </Col>
                <Col md="6" className="mb-3 mb-md-0">
                  <Button
                    type="submit"
                    className="btn--success --h-56 w-100"
                    value="Continue"
                    isSubmitting={isSubmitting}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      )}
    </Row>
  );
}

export function EducationalInformation({ ...props }) {
  const [user] = useGlobal("user");
  console.log(user)
  return (
    <Fragment>
      <h2 className="font-size-18 font-size-md-24 font-weight-700 mb-4">
        Your Educational Information
      </h2>

      <PersonnelInfo variant="inverse" className="pt-md-1">
        <PersonnelInfo.Data header="Name" content={user.fullname} />
        <PersonnelInfo.Data header="NSS Number" content={user.nssno} />
        <PersonnelInfo.Data
          header="Region of Service"
          content={user?.transcript?.region}
        />
        <PersonnelInfo.Data
          header="Institution attended"
          content={user.institution}
        />
        <PersonnelInfo.Data header="District of Service" content={user.district} />
        <PersonnelInfo.Data
          header="Qualification/Course of Study"
          content={user.qualification}
        />
      </PersonnelInfo>
    </Fragment>
  );
}

export function ConsentAuthorization({ ...props }) {
  const [user] = useGlobal("user");
  return (
    <div>
      <p className="font-size-18 font-size-md-24 mb-32 mb-md-48 font-weight-700">
        Electrionic Consent Authorization
      </p>

      <PersonnelInfo variant="inverse">
        <PersonnelInfo.Data
          header="Service Order Number"
          content={user?.transcript?.formno.toUpperCase()}
          contentCss="font-size-18"
        />
        <PersonnelInfo.Data
          header="Consent to Release information from Education Records"
          content={<ConsentMessage />}
          contentCss="red-text"
        />
        <PersonnelInfo.Data
          header="The undersigned individual authorises Ghana National Service Scheme to release their national service certificate  to individual (recipient) on this order through Courier"
          headerCss="font-size-16 line-height-13"
        />

        <PersonnelInfo.Data
          header="EX-NSS Personnel Name"
          content={user.fullname}
        />
      </PersonnelInfo>
    </div>
  );
}

export function DeliveryDetails({ deliveryData, ...props }) {
  const {
    addresses,
    area,
    landmark,
    region,
    phone,
    email,
    country,
  } = deliveryData.personnel;
  const [identification] = useGlobal("identification");
  console.log({identification})
  const [user] = useGlobal("user");

  return (
    <Fragment>
      <h2 className="font-size-18 font-size-md-24 font-weight-700 mb-4">
        Delivery Details & ID Document Details
      </h2>
      <div class="id-card-container">
      <IDCard identification={identification} user={user}/>
      </div> 
      

      <PersonnelInfo className="pt-md-1" variant="inverse">
        <PersonnelInfo.Data header="Address 1" content={user.transcript.address1} />
        <PersonnelInfo.Data
          header="Address 2"
          content={user.transcript.address2 || "None"}
        />
        <PersonnelInfo.Data header="Area/Neighbourhood" content={user.transcript.area_neighbourhood} />
        <PersonnelInfo.Data header="Closest Landmark" content={user.transcript.closest_landmark} />
        <PersonnelInfo.Data header="Region/State" content={user.transcript.region} />
        <PersonnelInfo.Data
          header="Country"
          content={
            /**
             * Get the country name from the country code
             */
            user?.transcript?.countryid === 1 ?
            "Ghana" :
            countryList()
              .getData()
              .find((c) => c.value.toUpperCase() === user.transcript?.countryid)
              ?.label
          }
        />
        <PersonnelInfo.Data
          header="Phone Number"
          content={
            /**
             * Prefix phone number with country phone code
             */
            countryPhoneCode[country]?.code || '' + user?.transcript?.phone_number
          }
        />
        <PersonnelInfo.Data header="Email Address" content={user?.transcript?.email_address} />
      </PersonnelInfo>
    </Fragment>
  );
}
