import React from "react";

export default function Bubble({
  size,
  color,
  content,
  icon,
  iconSize,
  className,
}) {
  return (
    <div
      className={`${className} ${color}-bg rounded-circle d-flex align-items-center justify-content-center`}
      style={{ width: parseInt(size), height: parseInt(size) }}
    >
      {content && content}
      {icon && (
        <i className={`material-icons white-text font-size-${iconSize}`}>
          {icon}
        </i>
      )}
    </div>
  );
}
