import React from "react";

import { Image } from "react-bootstrap";
import { Close } from "./Components";

import whatsappIcon from "Assets/img/icons/whatsapp.svg";

export const contactList = [
  { region: "All Regions", number: "+233544486683" }
  /*{ region: "Greater Accra", number: "0299011417" },
  { region: "Ashanti", number: "0299011413" },
  { region: "Upper West", number: "0299011414" },
  { region: "Brong Ahafo", number: "0299011420" },*/
];

export default function Support() {
  return (
    <div className="home-page home-page--support">
      <div className="home-page__image">
        <Close />
      </div>
      <div className="home-page__info">
        <div className="home-page__info__content">
          <div className="heading">
            <h1 className="heading__header">Support</h1>
            <h2 className="heading__sub-header">
              Having a challenge submitting your order? Contact us on whatsapp
              for issues with your request or system{" "}
              <a href="tel: +233544486683" className="default-text">
                054 448 6683
              </a>{"; "}
              alternatively, regional support contacts are available on the numbers below:
            </h2>
          </div>

          <div className="body d-flex mb-5">
            <div className="mr-4 mr-lg-5 regions">
              {contactList.map((contact, key) => {
                return <p key={key}>{contact.region}</p>;
              })}
            </div>
            <div className="mr-4 mr-lg-5 contacts">
              {contactList.map((contact, key) => {
                return (
                  <a
                    className="d-block default-text mb-24 font-weight-700"
                    key={key}
                    href={`tel: ${contact.number}`}
                  >
                    {contact.number}
                  </a>
                );
              })}
            </div>
            <div className="whatsapp">
              {contactList.map((contact, key) => {
                return (
                  <a href={`https://wa.me/${contact.number}`} key={key}>
                    <Image src={whatsappIcon} />
                  </a>
                );
              })}
            </div>
          </div>

          <div className="footer">
            <p className="mb-0 line-height-13">
              You can also use our live chat bottom right of your screen and if
              you cannot find your certificate after the search, send complaint
              email to{" "}
              <a
                className="red-text font-weight-700"
                href="mailto: certificatesupport@nss.gov.gh"
              >
                certificatesupport@nss.gov.gh
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
