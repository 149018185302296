import React, { useGlobal, useDispatch } from "reactn";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image
} from "@react-pdf/renderer";
import { useTitle } from "Utils";

import { Layout } from "Views/Components";
import Overview from "./Delivery/Overview";
import { namedRoutes } from "Routes";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    width: "100%",
    height: 300
  },
  section: {
    margin: 10,
    padding: 10
    //flexGrow: 1
  },
  text: {
    fontSize: 12,
    color: "#666"
  },
  heading: {
    fontSize: 16,
    marginVertical: 5
  },
  info: {
    marginVertical: 5
  }
});

export default function Transaction({ history }) {
  const [user] = useGlobal("user");
  console.log({ user });

  useTitle("Transaction");
  const logout = useDispatch("auth.logout");

  if(user?.invoice?.paid && parseFloat(user.invoice.paid) < 1.0 ){
    //alert('Payment Not Verified Yet');
    logout();
    //history.push(namedRoutes.dashboard.delivery.replace(":step", 5));
  }

  return (
    <Layout title="Request Details">
      <PDFViewer style={{ width: "100%", height: 700 }}>
        <Document style={{ width: "100%", height: 200 }}>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>NATIONAL SERVICE SCHEME CERTIFICATE REQUEST SUMMARY</Text>
            </View>
            <View style={{ flex: 2, flexDirection: "row", padding: 15 }}>
              <View style={{ flex: 1 }}>
                <Text>Educational Information</Text>

                <View style={styles.info}>
                  <Text style={styles.heading}>Fullname</Text>
                  <Text style={styles.text}>{user.fullname}</Text>
                </View>

                <View style={styles.info}>
                  <Text style={styles.heading}>NSS Number</Text>
                  <Text style={styles.text}>{user.nssno}</Text>
                </View>

                <View style={styles.info}>
                  <Text style={styles.heading}>Region Of Service</Text>
                  <Text style={styles.text}>{user?.transcript?.region}</Text>
                </View>

                <View style={styles.info}>
                  <Text style={styles.heading}>Institution</Text>
                  <Text style={styles.text}>{user.institution}</Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View style={styles.info}>
                  <Text style={styles.heading}>Request Number</Text>
                  <Text style={styles.text}>{user.transcript.formno}</Text>
                </View>


                <View style={styles.info}>
                  <Text style={styles.heading}>District Of Service</Text>
                  <Text style={styles.text}>{user.district}</Text>
                </View>

                <View style={styles.info}>
                  <Text style={styles.heading}>Qualification</Text>
                  <Text style={styles.text}>{user.qualification}</Text>
                </View>
              </View>
            </View>
            <Text style={{ marginHorizontal: 15}}>RECEPIENT's INFORMATION</Text>
            <View style={{ flex: 2, flexDirection: "row", padding: 15 }}>
            
              <View style={{ flex: 1 }}>
                

                <View style={styles.info}>
                  <Text style={styles.heading}>Recepient's Name</Text>
                  <Text style={styles.heading}>/Address</Text>
                  <Text style={styles.text}>
                  {user.contact.firstname}{ " " }
                  {user.contact.lastname}{ " " }
                  {user.contact.othername}{ " " } 
                  
                  </Text>
                  <Text style={styles.text}> 
                  {user.district}
                  
                  </Text>
                  <Text style={styles.text}>
                  {user.transcript.address1}{","}
                  
                  </Text>
                  <Text style={styles.text}>
                  {user.transcript.address2}{","}
                  
                  </Text>
                </View>
 
 
              </View>
              <View style={{ flex: 1 }}>
                <View style={styles.info}>
                  <Text style={styles.heading}>Phone Number</Text>
                  <Text style={styles.text}>{user.transcript.phone_number}</Text>
                </View>


                <View style={styles.info}>
                  <Text style={styles.heading}>Delivery Method</Text>
                  <Text style={styles.text}>{'Courier'}</Text>
                </View> 
              </View>
              <View style={{ flex: 1 }}>
                <View style={styles.info}>
                  <Text style={styles.heading}>Email Address</Text>
                  <Text style={styles.text}>{user.transcript.email_address}</Text>
                </View>


                <View style={styles.info}>
                  <Text style={styles.heading}>Courier AirwayBill Number</Text>
                  <Text style={styles.text}>{user.invoice.awb && user.invoice.awb.length>0 ? user.invoice.awb : "Awaiting" }</Text>
                </View> 
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: "row", padding: 15 }}></View>
          </Page>
        </Document>
      </PDFViewer>
    </Layout>
  );
}
