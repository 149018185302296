import React, { Fragment } from "react";
import { Button } from "Components";

export default function Back({ className, text, ...props }) {
  return (
    <Button
      value={
        <Fragment>
          <i className="material-icons">keyboard_arrow_left</i>
          <span className="d-block ml-2">{text ? text : "Back"}</span>
        </Fragment>
      }
      className={`btn--stateless --h-56 w-100 ${className}`}
      {...props}
    />
  );
}
