import React from "react";
import { Image } from "react-bootstrap";

import checkmarkIcon from "Assets/img/icons/checkmark.svg";
import Close from "./Components/Close";

export const requirementList = [
  "Personnel must have successfully completed their mandatory one (1) year (12 months) National service.",
  "Personnel must have had their annual assessment form filled with a fair assessment of their performance by their immediate supervisor or Head of the User Agency where served.",
  "Assessment forms must have been submitted at the various District offices of the scheme where the Agency is located",
  "Submit your NSS Number for verification in the field provided",
  "Submit your Means of Identification",
  "Fill in your details in the request form displayed",
  "Make payment for delivery using any of the several options available",
];

export default function Requirements() {
  return (
    <div className="home-page home-page--requirements">
      <div className="home-page__image">
        <Close />
      </div>
      <div className="home-page__info">
        <div className="home-page__info__content">
          <div className="heading">
            <h1 className="heading__header">Requirements</h1>
            <h2 className="heading__sub-header">
              For you to get your certificate delivered to you, you need to do
              the following
            </h2>
          </div>
          <div className="body">
            <div className="requirements">
              {requirementList.map((requirement, key) => {
                return (
                  <div className="requirements__details" key={key}>
                    <Image
                      src={checkmarkIcon}
                      className="requirements__details__icon"
                    />
                    <p className="requirements__details__content">
                      {requirement}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
