/* eslint-disable no-unreachable */
import React, {
  useGlobal,
  setGlobal,
  useState,
  useEffect,
  useDispatch
} from "reactn";
import { Col, Row, Form, Image } from "react-bootstrap";
import { Formik } from "formik";
import countryList from "react-select-country-list";
import * as Yup from "yup";
 
import { Field, Button, Select, countryPhoneCode } from "Components";
import { SideCard, PersonnelInfo, Back } from "Views/Components";
import { namedRoutes } from "Routes";
import axios from "axios";

import { BASE_URL } from "../../../Config";
import Certificate from "../Certificate";
import { logout } from "Reducers/auth";
//import PhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/lib/style.css'
import "react-phone-number-input/style.css";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
//import './style.css';
import _ from "lodash";
import PhoneInput,  {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber
} from "react-phone-number-input";
import InputT from "react-phone-number-input/input";
import flags from "react-phone-number-input/flags";
 
  

export const Schema = Yup.object({
  country: Yup.string().required(),
  digit_address: Yup.string()
    .nullable()
    .max(15, "Ghana Post GPS Address length is less than 15"),
  /*digit_address: Yup.string().test(
    "Digital Address Test Test",
    "Invalid Digital Address. Use the map to pick a valid address",
    value => {
      console.log({ value });
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://dgxpac311k64y.cloudfront.net/src/functions/php/verifyGhanaPostGPS.php?address=${value.replace(
              "-",
              ""
            )}`
          )
          .then(({ data }) => {
            console.log({ data });
            if (!data || data === "null") {
              resolve(false);
              return;
            }
            resolve(true);
          })
          .catch(e => {
            resolve(false);
            console.error(e);
          });
      });
    }
  ),*/
  email: Yup.string().email().required("Email is required"),
  /*email: Yup.string().test(
    "Phone Number Test",
    "Invalid Email. Make sure email exists",
    value => {
      console.log({ value });
      return new Promise((resolve, reject) => {
        axios
          .get(`https://api.usebouncer.com/v1/email/verify?email=${value}`, {
            headers: {
              "x-api-key": "xjYc8oSS1oHQ8XHfIpLvMXNx1sM9ZByyK9Zl6KSk"
            }
          })
          .then(({ data }) => {
            console.log({ data });
            const { email = "", reason = "", status = "" } = data;
            console.log({ email, reason, status });

            if(reason === "accepted_email"){
              console.log(true)
              resolve(true);
              return;
            }

            if(reason === "rejected_email"){
              console.log(false)
              resolve(false);
              return;
            }

            if(reason === "risky"){
              console.log(false)
              resolve(false);
              return;
            }
            console.log(false)

            resolve(false);
            return;
            
            //resolve(reason ===  && email === value);
          })
          .catch(e => {
            resolve(false);
            console.error(e);
          });
      });
    }
  ),*/
  phone: Yup.string().test(
    "Phone Number Test",
    "Invalid Phone Number",
    value => {
      console.log({ value });
      const ValidPhoneNumber = isValidPhoneNumber(value);
      console.log({ ValidPhoneNumber });
      return ValidPhoneNumber;
    }
  ),
  addresses: Yup.object({
    main: Yup.string()
      .required("Address is required")
      .max(100, "Address cannot be more than 100 characters"),
    others: Yup.string()
      .nullable() 
      .max(100, "Address cannot be more than 100 characters")
  }),
  area: Yup.string().typeError("Area is required").required("Area/Neighborhood is required"),
  landmark: Yup.string().typeError("Closest Landmark is required").required("Landmark is required"),
  cityid: Yup.number().typeError("City is required").required("City is required"),
  city: Yup.string().nullable(),
  region: Yup.string().typeError("State/Region is required").required("State/Region is required")
});

 

export default function Info({ history, next }) {
  const [deliveryData, setDeliveryData] = useGlobal("deliveryData");

  const [user] = useGlobal("user");
  console.log({user});

  const [gps_result] = useGlobal("gps_result");
  console.log({gps_result});

  const [loading, setLoading] = useState(true);
  console.log({ loading });

  const [loadingGPS, setLoadingGPS] = useState(false);
  console.log({ loadingGPS });

  const [cities, setCities] = useState([]);
  console.log({ cities });

  const [theThis, setThis] = useState(null);
  console.log({ theThis });

  const [wrongEmail, setWrongEmail] = useState(false);
  console.log({ wrongEmail });

  const [formSubmitting, setFormSubmitting] = useState(false);
  console.log({ formSubmitting });

  const [countries, setCountries] = useState([]);
  console.log({ countries });

  const [disableValues, setDisableValues] = useState(false);
  console.log({ disableValues });

  

  const logout = useDispatch("auth.logout");

  //setGlobal({userToTypeAddressThemselves: true});
  const [userToTypeAddressThemselves] = useGlobal("userToTypeAddressThemselves");
  console.log({userToTypeAddressThemselves});

  useEffect(() => {

    
    if(user?.invoice?.paid && parseFloat(user.invoice.paid) > 1.0 ){
      //alert('Payment Not Verified Yet');
      console.log(parseFloat(user.invoice.paid));
      logout();
      history.push(namedRoutes.home.index);
    }

    // Update the document title using the browser API
    console.log("hi");
    Promise.all([fetchCities(), fetchCountries()]).then(
      ([cities, countries]) => {
        console.log({ cities, countries });
        setCities(cities);
        setCountries(countries);
        setLoading(false);
      }
    );

    //setDisableValues(false);
    if(userToTypeAddressThemselves){
      setDisableValues(false);
    }
    else if(user?.transcript?.countryid === 1){
      setDisableValues(true);
    }
  }, []);

  const fetchCities = async _ => {
    return axios.get(`${BASE_URL}/api/cities`).then(({ data }) => data.cities);
    try {
      const response = await axios.get(`${BASE_URL}/api/cities`);
      const { cities: ct } = response.data;
      setCities(ct);
      console.log("citieess", cities);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchCountries = async _ => {
    return axios
      .get(`${BASE_URL}/api/countries`)
      .then(({ data }) => data.countries);
    try {
      const response = await axios.get(`${BASE_URL}/api/countries`);
      const { countries: ct } = response.data;
      setCountries(ct);
      console.log("countriess", countries);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const validateEmail = async (email) => {
    return axios
    .get(`https://api.usebouncer.com/v1/email/verify?email=${email}`, {
      headers: {
        "x-api-key": "xjYc8oSS1oHQ8XHfIpLvMXNx1sM9ZByyK9Zl6KSk"
      }
    })
    /*.then(({ data }) => {
      console.log({ data });
      const { email = "", reason = "", status = "" } = data;
      console.log({ email, reason, status });

      if(reason === "accepted_email"){
        console.log(true)
        resolve(true);
        return;
      }

      if(reason === "rejected_email"){
        console.log(false)
        resolve(false);
        return;
      } 
      
      //resolve(reason ===  && email === value);
    }) */
  }

  //setCities(['ojota', 'kano']);
  //console.log(cities);

  return (
    <div>
      <h1 className="mb-4 mb-md-5 font-size-18 font-size-md-24 text-center text-md-left">
        Ex NSS Personnel Delivery Details
      </h1>

      <Row className="justify-content-between">
        <Col lg="7">
          {loading ? (
            <div style={{textAlign:"center", paddingTop:"20%", height:"100%"}}><Image src={`/img/load.svg`} /></div>
          ) : (
            <Formik
              initialValues={{
                country: deliveryData?.personnel?.country || "gh",
                digit_address: user?.transcript?.digitaddress,
                email: user?.transcript?.email_address,
                phone: user?.transcript?.phone_number,
                addresses: {
                  main: user?.transcript?.address1,
                  others: user?.transcript?.address2
                },
                area: user?.transcript?.area_neighbourhood,
                landmark: user?.transcript?.closest_landmark,
                city: user?.transcript?.city,
                cityid: user?.transcript?.cityid,
                countryid: user?.transcript?.countryid,
                region: user?.transcript?.region,
                cities,
                countries
              }}
              validationSchema={Schema}
              children={props => (
                <InfoForm
                  {...props}
                  LoadingGPS={loadingGPS}
                  setLoadingGPS={setLoadingGPS}
                  setThis={setThis}
                  theThis={theThis}
                  history={history}
                  validateEmail={validateEmail}
                  setWrongEmail={setWrongEmail}
                  wrongEmail={wrongEmail}
                  disableValues={disableValues}
                  formSubmitting={formSubmitting}
                />
              )}
              onSubmit={ async (params, { setSubmitting, setErrors }) => {
                /**
                 * It would make sense to push the data to a global state
                 */
                /*setDeliveryData({ ...deliveryData, personnel: { ...params } });
              history.push(
                namedRoutes.dashboard.delivery.replace(":step", next)
              );*/

              // const { data } = await validateEmail(params.email);
              // console.log({ data });

              // const { email = "", reason = "", status = "" } = data;
              // console.log({ email, reason, status });

              // if(reason === "accepted_email"){
              //   console.log(true) ;
              // }else{
              //   setWrongEmail(true);
              //   //setErrors("email", "Email Invalid. Make sure it exists");
              //   return;
              // }
              
              
              setSubmitting(true);
              setFormSubmitting(true);
              //setSubmitting(true);
              //alert('ll')
                axios
                  .post(`${BASE_URL}/api/apply-for-certificate`, {
                    nssno: user.nssno,
                    dob: user.dob,
                    address1: params.addresses.main,
                    address2: params.addresses.others,
                    area_neighbourhood: params.area,
                    closest_landmark: params.landmark,
                    city: params.city,
                    cityid: params.cityid,
                    countryid: params.countryid,
                    region: params.region,
                    email_address: params.email,
                    phone_number: params.phone,
                    digitaddress: params.digit_address
                  })
                  .then(res => {
                    setFormSubmitting(false);
                    setSubmitting(false);
                    console.log(res);

                    const { transcript, invoice, link = null } = res.data;
                    //setGlobal({ user: {...user, transcript, invoice, cert: { link } } });
                    setGlobal({ user: { ...user, transcript, invoice } });
                    setDeliveryData({
                      ...deliveryData,
                      personnel: { ...transcript }
                    });
                    /*if(user.cert_type === 2){
                  alert("Your Certificate has been emailed to you");
                  history.push(namedRoutes.dashboard.delivery.replace(":step", 5)); 
                  //logout();
                  return;
                }*/
                    return history.push(
                      namedRoutes.dashboard.delivery.replace(":step", next)
                    );
                  })
                  .catch(e => {
                    setSubmitting(false);
                    setFormSubmitting(false);

                    console.log(e.response);
                    if (
                      e.response &&
                      e.response.status >= 400 &&
                      e.response.data.message
                    ) {
                      alert(e.response.data.message);
                      setErrors({ phone: e.response.data.message });
                    }
                  });
              }}
            />
          )}
        </Col>
        <Col lg="4">
          <SideCard header="Summary">
            <PersonnelInfo>
              <PersonnelInfo.Data header="Name" content={user.fullname} />
              <PersonnelInfo.Data header="School" content={user.institution} />
              <PersonnelInfo.Data
                header="NSS Number"
                content={user.nssno}
                contentCss="font-size-24"
              />
              <PersonnelInfo.Data header="Company" content={user.place} />
            </PersonnelInfo>
          </SideCard>
        </Col>
      </Row>
    </div>
  );
}

export function InfoForm(props) {
  console.log({ props });
  const {
    history,
    handleSubmit,
    isSubmitting,
    formSubmitting,
    values,
    setFieldValue,
    validateEmail,
    disableValues,
    setLoadingGPS,
    LoadingGPS,
    setErrors,
    theThis,
    setThis,
    setWrongEmail,
    wrongEmail
  } = props;
  const {
    country,
    digit_address,
    email,
    phone,
    addresses,
    area,
    landmark,
    city,
    cityid,
    countryid,
    region,
    cities,
    countries
  } = values;
  console.log(
    // countries.find(country => country.country_id == countryid).country_code
  );
  return (
    <Form className="mb-5 mb-lg-0">
       
      <Row>
        <Col md="6"> 
              <Field 
              //isDisabled={disableValues}
              isDisabled={true}
                name="countryid"
                //value={country || ""}
                value={`${countryid || ""}`}
                label={{ value: "Country" }}
                component={Select}
                //onlyOptions={["gh"]}
                //options={countryList().getData()}
                options={countries.map(country => {
                  return {
                    value: country.country_id,
                    label: country.country
                  };
                })} 
                className="px-0 --border-none"
                placeholder=""
                onChange={item => setFieldValue("countryid", item.value)}
              />
            </Col>

            <Col md="6">
            
              <Field
              disabled={disableValues} 
              name={"digit_address"}
            value={digit_address || ""}
            label={{ value: "Ghana POST GPS Address" }}
          />
            </Col>
           
        
      </Row>
      <Row>
      <Col md="6">
              <Field
                name="email"
                value={email || ""}
                label={{ value: "Email Address" }}
                onBlur={ async (e)=> {
                  //alert("h")
                  console.log({ e : e.target.value});
                  const { data } = await validateEmail(e.target.value);
                  console.log({ data });

                  const { email = "", reason = "", status = "" } = data;
                  console.log({ email, reason, status });

                  if(reason === "accepted_email"){
                    console.log(true) ;
                    setWrongEmail(false);
                  }else{
                    console.log("Wrong Email")
                    setWrongEmail(true);
                    return;
                  }
                }}
              />
              { wrongEmail ? <span style={{color: "red", marginTop: -30, position: "absolute"}}>Wrong Email</span> : null }
            </Col>
      <Col md="6">
          <div className={"form-group"}>
            <label className="form-label">Phone</label>
            <PhoneInput
              //countrySelectProps={{ unicodeFlags: true }}
              //inputComponent={({ value, onChange })=>{
              //  return <InputT onChange={ e => console.log(e) } value={value} />
            //}}
              style={{ padding: 10 }}
              defaultCountry={"GH"}
              international
              value={phone || ""}
              onChange={params => setFieldValue("phone", params)}
              error={
                phone
                  ? isValidPhoneNumber(phone)
                    ? undefined
                    : "Invalid phone number"
                  : "Phone number required"
              }
            />
            <span style={{ color: "red" }}>
              {phone && isValidPhoneNumber(phone) ? "" : "Invalid phone number"}
            </span>
          </div>
        </Col>

      </Row>
      <Row>
        <Col md="12">
          <Field
            disabled={disableValues}
            readonly
            name={`addresses.main`}
            value={addresses.main || ""}
            label={{ value: "Address (not P.O.Box)" }}
          />
        </Col>
        <Col md="12">
          <Field
            name={`addresses.others`}
            value={addresses.others || ""}
            label={{ value: "Address cont'd" }}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Field
            name="area"
            readonly
            disabled={disableValues}
            value={area || ""}
            label={{ value: "Area/Neighbourhood" }}
          />
        </Col>
        <Col md="6">
          <Field
            name="landmark"
            disabled={disableValues}
            value={landmark || ""}
            label={{ value: "Closest Landmark" }}
          />
        </Col>
        {countryid === 1 ? (
          <Col md="6">
            <Field
            isDisabled={disableValues}
              name="cityid"
              value={`${cityid || ""}`}
              label={{ value: "City" }}
              component={Select}
              options={cities.map(city => {
                return {
                  value: city.city_id,
                  label: city.city
                };
              })}
              placeholder=""
              className="px-0 --border-none"
              onChange={params => setFieldValue("cityid", params.value)}
            />
          </Col>
        ) : (
          <Col md="6">
            <Field  name="city" value={city || ""} label={{ value: "City" }} />
          </Col>
        )}
        <Col md="6">
          <Field
            name="region"
            disabled={disableValues}
            value={region || ""}
            label={{ value: "State/Region" }}
          />
        </Col>
        
        <Col md="6" lg="5">
          <Button
            value="Confirm & Continue"
            className="btn--success --h-56 w-100"
            onClick={handleSubmit}
            type="submit"
            isSubmitting={isSubmitting || formSubmitting}
          />
          
        </Col>
        <Col md="6" lg="5">
        <Back onClick={() => history.push(
                namedRoutes.dashboard.delivery.replace(":step", 1)
                )} />
        </Col>
      </Row>
    </Form>
  );
}
