import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { namedRoutes } from "Routes";

// pages
import Requirements from "./Home/Requirements";
import Support from "./Home/Support";
import Results from "./Home/Results";
import Search from "./Home/Search";
import Index from "./Home/";

export default function Home() {
  return (
    <Switch>
      <Route
        exact
        path={namedRoutes.home.requirements}
        component={Requirements}
      />
      <Route exact path={namedRoutes.home.support} component={Support} />
      <Route exact path={namedRoutes.home.search} component={Search} />
      <Route exact path={namedRoutes.home.results} component={Results} />
      <Route exact path={namedRoutes.home.index} component={Index} />
      <Route exact path={namedRoutes.home.completesetup} component={Index} />

      <Route path="/*">
        <Redirect to={namedRoutes.home.index} />
      </Route>
    </Switch>
  );
}
