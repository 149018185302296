import React from "react";
import { Card } from "react-bootstrap";
import NeedHelp from "Views/Components/NeedHelp";

export default function SideCard({ className, children, header }) {
  return (
    <Card className={`side-card ${className}`}>
      <Card.Body>
        <div className="heading">
          <p className="heading__header">{header}</p>
          <NeedHelp />
        </div>
        <div className="body">{children}</div>
      </Card.Body>
    </Card>
  );
}
