import React, { useGlobal, useEffect, useState, setGlobal } from "reactn";
import { Form, Row, Col, Card, Alert, Image } from "react-bootstrap";
import { Formik, setIn } from "formik";
import * as Yup from "yup";

import { Field, Select, Button } from "Components";
import { cleanPhoneNumber } from "Utils";
import { namedRoutes } from "Routes";
import paymentMethods from "./Components/paymentMethods";
import moment from "moment";
import axios from 'axios';
import { useTitle } from "Utils";
import closeIcon from "Assets/img/icons/mtn-logo.png";
//import { Image } from "react-bootstrap";
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';

import { BASE_URL } from '../../../Config';

export const Schema = Yup.object({
  payment_method: Yup.string().required(),
  phone: Yup.string().when("payment_method", (method, schema) =>
    ["mtn", "vodafone", "airtel-tigo"].includes(method)
      ? schema.max("9").required("Phone number is required")
      : schema
  ),
  voucher: Yup.string().when("payment_method", (method, schema) =>
    method === "vodafone" ? schema.required("Voucher is required") : schema
  ),
});

export default function Payment({ history, next, prev }) {

  useTitle("Payment");
  
  const [user] = useGlobal("user");
  const [loading, setLoading] = useState(false);
  const [paymentTypeVerify, setPaymentTypeVerify] = useState(null);
  const [fetchingInvoice, setFetchingInvoice] = useState(false);
  const [invoice, setInvoice] = useState(null);
  console.log({ user })
  const [deliveryData, setData] = useGlobal("deliveryData");
  console.log({ loading });

  if(user?.invoice?.paid && parseFloat(user.invoice.paid) > 1.0 ){
    //alert('Payment Not Verified Yet');
    history.push(namedRoutes.dashboard.delivery.replace(":step", 6));
  }

  const fetchLatestInvoice = (formNo = 'x') => {
    setFetchingInvoice(true);
    const url = `${BASE_URL}/api/invoice/fetch-invoice?form-no=${formNo}`;
    axios.get(url).then(res => {
      console.log(res);
      setInvoice(res.data.invoice);
      setFetchingInvoice(false);
    }).catch(e => {
      setFetchingInvoice(false);
      console.error(e.message);
    })
  }

  const verifyPayment = ({ orderId, token }) => {
    setLoading(true);
    setPaymentTypeVerify("express");
    const url = `${BASE_URL}/api/payment/confirm-express-payment?order-id=${orderId}&token=${token}`;
    axios.get(url).then(res => {
      console.log();
      const { paymentVerified, invoice } = res.data;

      if(paymentVerified){
        //alert("Payment Verified");
        setLoading(false);
        setGlobal({ user: {...user, invoice } });
        return history.push(namedRoutes.dashboard.delivery.replace(":step", 6));
      }
      
      console.log('Payment Not Verified');
      setTimeout(() => verifyPayment({ orderId, token }), 5000);
      
      
    }).catch(e => {
      setLoading(false);
      console.error(e.message);
    })
  }

  const verifyMTNPayment = async ({ invoiceNumber }) => {
    setLoading(true);
    setPaymentTypeVerify('mtn');
    let times = 0;
    const url = `${BASE_URL}/api/payment/verify-mtn/${invoiceNumber}`;

     
      try {
        console.log({ times });
        const res = await axios.get(url);
         
        const { response, invoice } = res.data;
        console.log({ response, invoice });
        
        if (response.response == "0000") {
          //alert('Payment verified');
          setGlobal({ user: {...user, invoice } });
          return history.push(namedRoutes.dashboard.delivery.replace(":step", 6)); 
          //break;
        }
        
        
        console.log('Payment Not Verified');
        setTimeout(() => verifyMTNPayment({ invoiceNumber }), 5000);
        /*if(times == 60){
          alert("Payment not confirmed")
          setLoading(false);
        }*/
        
         

      } catch (e) {
        setLoading(false);
        console.error(e.message);
      }
      

  }

  useEffect(() => {
    // ...

    const urlParams = new URLSearchParams(window.location.search);
    const callback = urlParams.get('callback');
    if (callback == 'true') {
      const token = urlParams.get('token');
      const orderId = urlParams.get('order-id');
      console.log({ orderId, token })


      verifyPayment({ orderId, token });

      console.log('Is Callback');

    } else fetchLatestInvoice(user.transcript.formno);


  }, []);

  if (loading) {
    
    if(paymentTypeVerify === "mtn")
    return <div style={{ flexDirection: 'column', display: 'flex' }}>
      <div style={{ textAlign: 'center' }}>
      <h3>Payment Confirmation</h3>

      <Image width={100} height={70} src={closeIcon} />
      <br/>
      <h4>Payment Pending Approval</h4>
      <br/>
      <p>1. To Complete Payment Dial *170#</p>
      <p>2. Choose Option 6 (Wallet), then Option 3 (My Approvals)</p>
      <p>3. Next, Enter PIN, select transaction</p>
      <p>4. Choose Option 1 to approve</p>
      <Image style={{ alignSelf: 'center' }} height={100} src={`/img/loading.gif`} />
      <p style={{ textAlign: 'center' }}>Verifying Payment</p>
      </div>
    </div>


    return(
      <div style={{ flexDirection: 'column', display: 'flex' }}>
      
      <Image style={{ alignSelf: 'center' }} height={100} src={`/img/loading.gif`} />
      <p style={{ textAlign: 'center' }}>Verifying Payment</p>
       
    </div>
    )
  }

  if (fetchingInvoice) {
    return <div style={{ flexDirection: 'column', display: 'flex' }}>
      <Image style={{ alignSelf: 'center' }} src={`/img/loading.gif`} />
      <p style={{ textAlign: 'center' }}>Loading</p>
    </div>
  }

  return (
    <div className="payments-page">
      <Formik
        initialValues={{
          invoice,
          payment_method: deliveryData ?.payment ?.payment_method || "mtn",
          voucher: deliveryData ?.payment ?.voucher || "",
          phone: deliveryData ?.payment ?.phone
            ? cleanPhoneNumber(deliveryData.payment.phone)
            : "",
        }}
        validationSchema={Schema}
        children={(props) => <PaymentForm invoice={invoice} {...props} />}
        onSubmit={async (params, { setSubmitting }) => {
          let parsedPhoneNumber = "";
          if (params.phone) {
            //params.phone = cleanPhoneNumber(params.phone, true);
            parsedPhoneNumber = cleanPhoneNumber(params.phone, true);
          }

          if (params.payment_method === "expresspay") {
            const url = `${BASE_URL}/api/payment/get-express-url/${invoice.requestno}`;

            const response = await axios.get(url);
            console.log({ response });
            document.location.href = response.data.redirect_url;
            return;
          }

          if (params.payment_method === "mtn") {

            alert("MTN Payment Not available");
            return;
            const url = `${BASE_URL}/api/payment/mtn/${invoice.requestno}/${parsedPhoneNumber}`;

            const response = await axios.get(url);
            console.log({ response });
            if (response.data.response.response == "0000") {
              console.log('Verifying..')
              verifyMTNPayment({ invoiceNumber: response.data.response.invoiceNumber })
            } else {
              alert('not verified')
            }
            return;
          }



          setData({
            ...deliveryData,
            payment: { ...params, created_at: moment() },
          });
          history.push(namedRoutes.dashboard.delivery.replace(":step", next));
        }}
      />

      <div className="available-methods mt-5 pt-3 pt-md-0">
        <p className="font-size-18 font-size-md-24 mb-32 mb-md-48">
          Available Payment Methods
        </p>
        <div className="available-methods__cards d-flex flex-column flex-md-row">
          {paymentMethods.map((method, key) => (
            <span className="light-card" key={key}>
              <Image src={method.icon} className={`--${method.value}`} />
              <span>{method.label}</span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export function PaymentForm({
  handleSubmit,
  values,
  isSubmitting,
  setFieldValue,
  invoice = {}
}) {
  const { payment_method } = values;
  const selectedMethod = paymentMethods.find(
    (method) => method.value === payment_method
  );
  console.log({ values })

  const [user] = useGlobal("user");
  return (
    <Form>
      <Row className="justify-content-between flex-column-reverse flex-md-row">
        <Col md="5">
          <h1 className="font-size-24 d-none d-md-block mb-5 pb-2">
            Review and Confirm Your Order
          </h1>
          <Field
            name="payment_method"
            options={paymentMethods}
            value={payment_method || ""}
            component={Select}
            onChange={(option) => setFieldValue("payment_method", option.value)}
            className="px-0 --border-none"
            placeholder="Select payment method"
            containerProps={{ className: "contained mb-md-5" }}
          />

          {payment_method && (
            <Form.Group className="mb-md-5">
              <p className="m-0 line-height-13">{selectedMethod ?.info}</p>
            </Form.Group>
          )}
 

          {selectedMethod ?.fields ?.map((field, key) => {
            return (
              <Field
                name={field}
                key={key}
                label={{
                  value:
                    field === "phone" ? "Enter phone number" : `Enter ${field}`,
                }}
                value={values[field]}
                containerProps={{ className: "contained mb-md-5" }}
                {...(field === "phone" && {
                  startadornment: {
                    props: { className: "pale-grey-bg px-2 py-1" },
                    children: "+233",
                  },
                })}
              />
            );
          })}

          <Form.Group className="contained">
            <Button
              value={
                selectedMethod ?.label
                  ? `Pay with ${selectedMethod ?.label}`
                  : "Select to pay"
              }
              {...(!payment_method && { disabled: true })}
              className="btn--success --h-56 w-100"
              onClick={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </Form.Group>
        </Col>

        <Col md="5" lg="4">
          <h1 className="font-size-18 d-block d-md-none mb-4 pb-3">
            Review and Confirm Your Order
          </h1>
          <Card className="mb-4 mb-md-5">
            <Card.Body className="p-0">
              <div className="d-flex align-items-center justify-content-between px-18 py-3 px-md-30">
                <p className="m-0 font-weight-bold font-size-18">
                  Order Summary
                </p>
              </div>
              <span className="underline" />
              <div className="px-18 px-md-30 py-4">
                <div className="flex-table">
                  <div className="data">
                    <p className="data__header">Delivery Fee by Courier</p>
                    <p className="data__content">GHS {invoice ?.amount || 0}</p>
                  </div>
                  <span className="underline mb-24" />
                  <div className="data">
                    <p className="data__header">Order Total</p>
                    <p className="data__content font-weight-700 font-size-32">
                      GHS {invoice ?.amount || 0}
                    </p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          {selectedMethod ?.instructions && (
            <div className="pt-md-2">
              <Alert className="alert--primary">
                <div className="alert__content">
                  <ol className="pl-4 mb-0">
                    {selectedMethod ?.instructions.map((instruction, key) => (
                      <li
                        key={key}
                        className={
                          selectedMethod ?.instructions.length - 1 !== key
                            ? "mb-2"
                            : ""
                        }
                      >
                        {instruction}
                      </li>
                    ))}
                  </ol>
                </div>
              </Alert>
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
}
