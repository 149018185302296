import React from "react";

function Data({ header, content, headerCss = "", contentCss = "", ...props }) {
  return (
    <div className="data" {...props}>
      {header && (
        <div className={`data__header ${headerCss} ${!content && "mb-0"}`}>
          {header}
        </div>
      )}
      {content && (
        <div className={`data__content ${contentCss}`}>{content}</div>
      )}
    </div>
  );
}

function PersonnelInfo({ variant = "", className, children, ...props }) {
  return (
    <div
      className={`personnel-info${variant ? "--" + variant : ""} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
}

PersonnelInfo.Data = Data;

export default PersonnelInfo;
