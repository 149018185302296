import vodafoneCashImage from "Assets/img/site/vodafone-cash.png";
import airtelTigoImage from "Assets/img/site/airtel-tigo.png";
import masterCardImage from "Assets/img/site/master-card.png";
import expressPayImage from "Assets/img/site/express-pay.png";
import mtnMomoImage from "Assets/img/site/mtn-momo.png";
import ghLinkImage from "Assets/img/site/gh-link.png";

const paymentMethods = [
  // {
  //   value: "mtn",
  //   label: "MTN Mobile Money",
  //   icon: mtnMomoImage,
  //   info:
  //     "Allow cash out by entering your MTN mobile money number in the field provided (Ghana MTN Number Only) and click with pay with mobile money",
  //   instructions: [
  //     "Dial *170 and selection option 6, My Wallet",
  //     "Select Option 3 for My Approvals",
  //     "Enter PIN to get your pending approval list",
  //     "Selection Pending transactions to approve",
  //     "Select Option 1 YES to approve transaction",
  //   ],
  //   fields: ["phone"],
  // },
  /*{
    value: "vodafone",
    label: "Vodafone Cash",
    icon: vodafoneCashImage,
    info: "Please enter voucher and Vodafone cash number in the field provided",
    fields: ["phone", "voucher"],
  },
  {
    value: "airtel-tigo",
    label: "Airtel/Tigo Money",
    icon: airtelTigoImage,
    info: "Some information here",
    fields: ["phone"],
  },*/
  {
    value: "expresspay",
    label: "Express Pay",
    icon: expressPayImage,
    info:
      "You will be redirected to then expresspay website to make payment. After that you'll be redirected back here to continue",
  },
  /*{
    value: "mastercard",
    label: "Master Card",
    icon: masterCardImage,
    info:
      "You will be redirected to a different website to make payment. After that you'll be redirected back here to continue",
  },
  {
    value: "ghlink",
    label: "GH Link",
    icon: ghLinkImage,
    info:
      "You will be redirected to the gh link website to make payment. After that you'll be redirected back here to continue",
  },*/
];

export default paymentMethods;
